import {
    Button,
    Typography,
    Grid,
    Container,
    Paper,
    TextField,
  
  } from "@mui/material";
import React, {useState, useEffect} from 'react'
import Masta from "../../images/MASTA-logo.png";
import Input from "./UpdateInput/Input";
import axios from "axios"
import {useNavigate, useParams} from "react-router-dom"
import { resetPassword } from "../../api";
import { toast } from "react-toastify";
const UpdatePassword = () => {
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const {id} = useParams()
  const user = JSON.parse(localStorage.getItem("profile")) 

  const navigate = useNavigate();
   

       
      
const handleSubmit = async (e)=> {
    e.preventDefault()
    if(cpassword !== password){
      toast.error("Password does not match")
    }
    try {
      const response = await axios.patch(`https://masta-api-ae9y.onrender.com/gigs/profile/user/updatePassword/${id}`, {
        _id: id,
        password,
      });

      console.log(response.data);
    } catch (error) {
      console.error('Error updating password:', error);
    }
}
  return (
    <Container className="">
    <Paper
      className={
        "mt-[64px] bg-primary mb-[10px] flex flex-col items-center p-[8px]   justify-center "
      }>
      
      <div className=" flex items-center justify-center">
        <img
          src={Masta}
          alt="Logo"
          className="w-[30%] lg:w-[20%]  object-cover"
        />
      </div>

     
     
      <form className={"w-[100%] mt-[24px] "} onSubmit={handleSubmit}>
        <h2
          className="text-[25px] font-medium  uppercase mb-3"
          color="primary">
          {" Personal Details"  }
        </h2>
        <div  className="flex gap-2 items-center lg:flex-row flex-col">
        <input
          type="password"
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input  border-[1px] border-gray-200"
          
        />
        <input
          type="password"
          placeholder=" Confirm New Password"
          value={cpassword}
          onChange={(e) => setCPassword(e.target.value)}
          className="input border-[1px] border-gray-200"
        />      
        </div>
        <div className="mt-3 w-[100%] ">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={"mt-5 bg-accent"}>
            {"Update Profile"}
          </Button>
        </div>
        
        
      </form>
    </Paper>
  </Container>
  )
}

export default UpdatePassword