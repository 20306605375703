import React from "react";
import {
  InputAdornment,
  IconButton,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
const Input = ({
  name,
  half,
  handleChange,
  label,
  autoFocus,
  type,
  handleShowPassword,
  placeholder,
}) => {
  return (
    <Grid item xs={12} sm={half ? 6 : 12}>
      <div className="">
        <TextField
          name={name}
          onChange={handleChange}
          variant="outlined"
          
          placeholder={placeholder}
          fullWidth
          label={label}
          autoFocus={autoFocus}
          type={type}
          InputProps={
            name === "password"
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword}>
                        {type === "password" ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : null
          }
        />
      </div>
    </Grid>
  );
};

export default Input;
