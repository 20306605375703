import React from 'react'
import { Link } from 'react-router-dom'
import PlumbingCat from "../../images/plumbingCat.jpg"
import MechanicCat from "../../images/MechanicCat.webp"
import ElectricianCat from "../../images/ElectricianCat.jpg"
import FashionCat from "../../images/FashionCat.jpg"
import CleaningCat from "../../images/CleaningCat.png"
import CarpentryCat from "../../images/CarpentryCat.jpeg"
import {Swiper, SwiperSlide } from 'swiper/react'
import {Pagination, Navigation } from 'swiper/modules'


const Category = () => {
  const categoryData = [
    {
      id:1, 
      CategoryName: "Plumbing",
      img: PlumbingCat,
    },
    {
      id:2, 
      CategoryName: "Mechanic",
      img: MechanicCat,
    },
    {
      id:3, 
      CategoryName: "Fashion",
      img: FashionCat,
    },
    {
      id:4, 
      CategoryName: "Electrician",
      img: ElectricianCat,
    },
    {
      id:5, 
      CategoryName: "Carpentry",
      img:CarpentryCat,
    },
    {
      id:6, 
      CategoryName: "Cleaning",
      img: CleaningCat,
    },
    {
      id:7,
      CategoryName: "Beutician",
      img: "https://5.imimg.com/data5/ZW/OO/ES/ANDROID-85896635/product-jpeg-500x500.jpg",
    },
    {
      id:8,
      CategoryName: "Repairs",
      img: "https://cdn.pixabay.com/photo/2017/08/20/20/16/tool-2663036_1280.jpg",
    },
    {
      id:7,
      CategoryName: "Catering Services",
      img: "https://media.istockphoto.com/id/650655146/photo/catering-food-wedding-event-table.jpg?s=612x612&w=0&k=20&c=ATGYgW8bM_559jJ5aUNO4HlJqpkOWUmNNMMflx5kajo=",
    },
    {
      id:7,
      CategoryName: "HairStyling",
      img: "https://img.freepik.com/premium-photo/hairdresser-beauty-salon-makes-hairstyle-girl-bride-with-long-curly-brown-hair_118086-3924.jpg",
    },
    {
      id:7,
      CategoryName: "Painting",
      img: "https://mywallworks.com/wp-content/uploads/2018/08/hiring-a-painter.jpeg",
    },
    {
      id:7,
      CategoryName: "Real Estate",
      img: "https://img.freepik.com/free-photo/modern-residential-district-with-green-roof-balcony-generated-by-ai_188544-10276.jpg?size=626&ext=jpg&ga=GA1.1.632798143.1705795200&semt=ais",
    },
]
  return (
    <div className='  my-6  '>
      <h3 className='text-[18px] lg:text-[25px]  ml-[20px] mx-auto '>  Categories</h3>
    <div className='my-3 flex  '>
      
      
      <div  key={Math.random()} className='flex justify-center  gap-2 lg:gap-1 flex-wrap items-center'>
        <Swiper 
        modules={[Pagination, Navigation]}
        loop={true}
        navigation={true}
        autoplay={true}
        style={{
          "--swiper-pagination-color": "#f64646",
          "--swiper-pagination-bullet-inactive-color": "#f64646",
          "--swiper-pagination-bullet-inactive-opacity": "1",
          "--swiper-pagination-bullet-size": "16px",
          "--swiper-pagination-bullet-horizontal-gap": "6px",
          "--swiper-navigation-color": "#7a1313",
        }}
        breakpoints={{
          320: {
            slidesPerView: 3,
            spaceBetween: 30,
            
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30, 
            
          },
          1024: {
            slidesPerView: 4,
            spaceBetween:30,
          
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 30,
            
          },
        }}
        
         className='ml-3 productSlider max-w-[360px] lg:max-w-[1410px]'
        >
{categoryData.map((data, index)=> (
          <SwiperSlide key={index}>
<Link to={`/gigs/category/${data.CategoryName}`}  key={data.id} className=' bg-primary p-3  cursor-pointer shadow-gray-600 shadow-2xl  hover:shadow-gray-700 opacity-95 rounded-[5px]
            flex flex-col item-center'>
              <img src={data.img} alt={data.CategoryName} className='mb-[20px]  object-fill  h-[100%] w-[300px] lg:h-[150px] ' />
              <h3 className='text-[12px] lg:text-[20px] text-center bg-accent text-black font-medium rounded-[8px] p-2  ' >{data.CategoryName}</h3>
            </Link>
          </SwiperSlide>
            
          ))
        }
        </Swiper>
        
      </div>
    </div>
    </div>
  )
}

export default Category