import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Artisan from './Admin/Artisan';
const Filter = () => {
 const [skill, setSkill] = useState("")
 const [location, setLocation] = useState("")
const [ searchResults, setSearchResults] = useState([])


  const navigate = useNavigate();
  const handleSearch = async () => {
    try {
      const response = await axios.get(`https://masta-api-ae9y.onrender.com/user/user/filter?skill=${skill}&location=${location}`);
      setSearchResults(response.data);

    } catch (error) {
      console.error(error);
    }
  };
  const filter = (e)=> {
    e.preventDefault()
    handleSearch()
  }
  return (
    <div className='flex flex-col w-full p-2 '>
      <h2 className='w-full py-2 font-semibold text-black bg-accent  text-[18px] flex justify-center items-center'>Filter</h2>
      {/* Skill */}
      <form onSubmit={filter} >
      <div className='w-full my-2 '>
      <select defaultValue={"DEFAULT"} className="block appearance-none w-[100%]    font-medium border border-gray-300 hover:border-gray-500  py-4  px-6 rounded-md leading-tight focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200  category  text-black  outline-none active:outline-none  " onChange={(e)=> setSkill(e.target.value)}  >
  <option disabled value="DEFAULT" className="text-acccent"   >Select a Profession</option>
              {
                ["A.C & Refrigeration Services", "Aluminium & Glass", "Appliances & Electronics","AUTO - Mechanic & Rewire & Panel", "Barber", "Beautician", "Brick Layer & Tiller & POP", "Carpentary Services", "Catering Services", "Cleaning & Laundary & Fumigation", "Dispatch Rider", " DSTV & CCTV", "Electrical & Inverter Services", "HairStylist", "Haulage & Movers", "Home Interior Fitting", "Make-Up Artist", "Painter & Screeder & Wallpaper" , " Phone & Laptop", "Photographer & Video", "Plumbing Services", "Real Estate Realtor", "Testing-Official Use", "Vehicle Towing", "Welder Services", "Fashion Designer"].map((skills)=> (
                  <option key={skills} value={skills}  className=" py-6 border-b-[1px] border-gray-300  font-medium h-[100px] overflow-scroll">{skills}</option>
                ))
              }
</select>
      </div>
      <div className='w-full my-4 h-[50px] '>
      <select defaultValue={"DEFAULT"} className="block appearance-none w-[100%]    font-medium border border-gray-300 hover:border-gray-500  py-4  px-6 rounded-md leading-tight focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200  category  text-black  outline-none active:outline-none  "   onChange={(e)=> setLocation(e.target.value)}>
  <option disabled value="DEFAULT" className="text-acccent"  >Select Location</option>
              {
                [ "Agege",
                "Ajeromi-Ifelodun",
                "Alimosho",
                "Amuwo-Odofin",
                "Apapa",
                "Badagry",
                "Epe",
                "Eti-Osa",
                "Ibeju-Lekki",
                "Ifako-Ijaiye",
                "Ikeja",
                "Ikorodu",
                "Kosofe",
                "Lagos Island",
                "Lagos Mainland",
                "Mushin",
                "Ojo",
                "Oshodi-Isolo",
                "Shomolu",
                "Surulere"].map((skills)=> (
                  <option key={skills}  value={skills}  className=" py-6 border-b-[1px] border-gray-300  font-medium h-[100px] overflow-scroll" >{skills}</option>
                ))
              }
</select>
      </div>
      <button className='px-3 py-1 bg-accent text-black  rounded-[5px] w-full'> Submit </button>
      </form>
     {
      searchResults?.map((result, index)=> (
        <div key={result}>
  <Artisan data={result} key={result} />
        </div>
      
      ))
     }
   


      
    </div>
  )
}

export default Filter