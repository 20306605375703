import React, { useEffect, useRef, useState } from 'react'
import SendIcon from '@mui/icons-material/Send';
import { useDispatch } from 'react-redux';
import {commentGig} from "../actions/auth"
import moment from 'moment';
const Comment = ({gigs}) => {

 
  const [comments, setComments] = useState(gigs?.comments)

  const [comment, setComment] = useState("")
  const dispatch = useDispatch()
  const commentsRef = useRef()
  const user = JSON.parse(localStorage.getItem("profile"))
  const name = user?.result?.firstName+ " " + user?.result?.lastName

  const handleComment = async (e)=> {
    const finalComment = `${name} : ${comment}  `
    const newComments =  await dispatch(commentGig(finalComment, gigs?._id))
   
    window.location.reload()
    setComment("")
    setComments(newComments)
    setComment("")
  
    commentsRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  useEffect(() => {
    
  }, [dispatch,comment])
  return (
    <div className=' mb-4 container mx-auto bg-[whitesmoke] p-2   rounded-[8px] shadow shadow-gray-700 text-gray-700'>
      <div className=' max-h-[200px] overflow-y-auto  overflow-x-hidden'>
      {
        comments?.map((c,i) => (
          <h6 key={i} className='my-4 text-[15px] lg:text-[20px] '>
            <strong>
            {c?.split(": ")[0]}  
            </strong> <span> : </span>
            {c?.split(":")[1]} 
          </h6>
        ))
      }
      <div ref={commentsRef}/>
      </div>
    
      {user?.result?.firstName && (
        <div className='shadow-gray-400 shadow  p-2 rounded-[8px] '>
         <h3 className='font-semibold lg:text-[25px]  text-[18px] '>Write a comment</h3>
      <form  onSubmit={handleComment}  className='flex items-center gap-1 '>
        <input value={comment} placeholder='Write a Comment...' onChange={(e)=> setComment(e.target.value)} className='p-5 rounded-[5px] outline-none w-full flex-1 border-[1px] border-gray-300   bg-[white] text-black'/>
        <button className='bg-accent text-black  py-5 px-10 rounded-[5px] '>
          <SendIcon fontSize=''/>
        </button>
      </form>
        </div>
      )}
     
    </div>
  )
}

export default Comment 