import "./App.css";
import Navbar from "./constants/Navbar/Navbar.jsx";
import Footer from "./constants/Footer/Footer.jsx";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";

import Home from "./components/Home/Home.jsx";
import Contact from "./containers/Contact.jsx";
import Blogs from "./containers/Blogs.jsx";
import Services from "./containers/Services/Services.jsx";
import ArtisanGigs from "./pages/ArtisanGigs.jsx";
import { useDispatch } from "react-redux";
import { getCategory, getGigs } from "./actions/index.js";
import { useEffect, useState, Suspense } from "react";
import Auth from "./components/Auth/Auth.jsx";
import Profile from "./pages/Profile.jsx";
import Search from "./components/Search/Search.jsx";
import GigsDetails from "./components/GigsDetails/GigsDetails.jsx";
import Comment from "./components/Comment.jsx";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import UpdateAuth from "./components/Auth/UpdateAuth.jsx";

import Listings from "./components/Listing/Listings.jsx";

import Home2 from "./components/Dummy/Home2.jsx";
import Result from "./components/Dummy/Result.jsx";
import UpdatePassword from "./components/Auth/UpdatePassword.jsx";
import Admin from "./components/Admin/Admin.jsx";
import Category from "./containers/Category.jsx";
import Filter from "./components/Filter.jsx";
import Filters from "./pages/Filters.jsx";

function App() {
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGigs());
  }, [currentId, dispatch]);

  const user = JSON.parse(localStorage.getItem("profile"));
  // console.log(user);
  return (
    <div className="bg-body ">
      {/* <Routes>
        <Route path="/" element={<Home2 />} />
        <Route element={!user ? <Auth /> : <Home2 />} path="/auth" />
        <Route element={<Result />} path="/result" />
      </Routes> */}
      <div className="">
        <div
          className="w-[100%]  
      ">
          <Navbar />
          <Routes>
            <Route element={<Home setCurrentId={setCurrentId} />} path="/" />
            <Route element={<Contact />} exact path="/contact" />
            <Route element={<Admin />} exact path="/admin" />
            <Route element={<Filters />} exact path="/filter" />
            <Route
              element={<UpdatePassword />}
              exact
              path="/updatePassword/:id"
            />
            <Route element={<Search />} exact path="/search" />

            <Route
              element={<UpdateAuth />}
              currentId={currentId}
              exact
              path="/user/update/:id"
            />
            <Route
              element={<GigsDetails setCurrentId={setCurrentId} />}
              exact
              path="/gigs/:id"
            />
            <Route element={<Category />} exact path="/category" />
            <Route
              element={<Services setCurrentId={setCurrentId} />}
              exact
              path="/gigs/category/:cat"
            />
            <Route
              element={<Listings setCurrentId={setCurrentId} />}
              exact
              path="/listings"
            />

            <Route element={!user ? <Auth /> : <Home />} path="/Auth" />
            <Route element={<Profile />} exact path="/gigs/profile/:id" />
            <Route
              element={
                <ArtisanGigs
                  currentId={currentId}
                  setCurrentId={setCurrentId}
                />
              }
              exact
              path="/gigs"
            />
          </Routes>
          <Footer />
        </div>
        <ToastContainer
          position="bottom-center"
          className={"z-40 "}
        />
      </div>
    </div>
  );
}

export default App;
