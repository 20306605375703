import React from "react";
import Services from "../../containers/Services";
import MainSlider from "./MainSlider";
import Mechanic from "../../images/Mechanic.jpg";
import Plumbing from "../../images/plumbing1.jpg";
import { Link } from "react-router-dom";
const Hero = () => {
  return (
    <section className="mb-[30px] pt-0 lg:pt-0 rounded-[5px]    mx-auto  mt-[20px] ">
      <div className=" mx-auto">
        <div className="bg-primary flex-col lg:flex-row  my-1  p-1  flex gap-2 rounded-[5px] ">
          {/* Services */}
          <div className=" flex-[0.2]   hidden lg:flex  shadow-gray-700 p-2 rounded-[5px] shadow-md">
            {" "}
            <Services />{" "}
          </div>
          {/* Main Slider */}
          <div className=" w-full flex-[0.6] max-w-lg lg:max-w-[743px] mx-auto">
            {" "}
            <MainSlider />{" "}
          </div>
          {/* Sub Slider  */}
          <div className=" flex-col gap-y-[30px] w-full  max-w-lg mx-auto h-[400px]  text-black hidden lg:flex  relative">
            <div className="grad flex-1 h-[250px] rounded-[8px] overflow-hidden relative p-6 ">
              <div className="flex flex-col max-w-[144px] h-full justify-center fixed -top-4  left-2     z-[7] ">
                <div className="text-[20px] uppercase font-medium leading-tight mb-4  ">Save 35% all Repair Services</div>
                <Link to={"#"} className="uppercase  object-fill bg-accent p-2 rounded-[5px] font-semibold">
                  {" "}
                  Repair Now!!
                </Link>
              </div>
              <img className="fixed z-20  -top-4  right-0  object-fill" src={Mechanic} alt="" />
            </div>
            <div className="grad flex-1 h-[250px] rounded-[8px] overflow-hidden relative p-6 ">
              <div className="flex flex-col max-w-[144px] h-full justify-center fixed bottom-4  left-2    z-[7] ">
                <div className="text-[20px] uppercase font-medium leading-tight mb-4 ">Save 25% all Plumbing Services</div>
                <Link to="#" className="uppercase  object-fill bg-accent p-2 rounded-[5px] font-semibold">
                  {" "}
                  Fix Now!!
                </Link>
              </div>
              <img className="fixed z-20  -top-4 right-0  object-fill" src={Plumbing} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
