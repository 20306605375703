import axios from "axios";
const PORT = process.env.MASTA_SERVER_URL;
const API = axios.create({ baseURL: "https://masta-api-ae9y.onrender.com" });
// const API = axios.create({ baseURL: "https://masta-api-ae9y.onrender.com" });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }

  return req;
});

export const fetchPostsBySearch = (searchQuery) => {
  console.log(searchQuery, "searchQuery debug");
  return API.get(
    `/gigs/search?searchQuery=${searchQuery.search || "none"}
    }`
  );
};
export const fetchGigs = () => API.get("/gigs");
export const fetchCategory = (cat) => API.get(`/gigs/category/${cat}`);
export const fetchDetails = (id) => API.get(`/gigs/${id}`);
export const fetchUserData = (id) => API.get(`/gigs/profile/${id}`);
export const fetchUser = (id) => API.get(`/chat/chats/${id}`);
export const createGig = (newGig) => API.post("/gigs", newGig);
export const likeGig = (id) => API.patch(`/user/user/${id}/likeGig`);

export const deleteGig = (id) => API.delete(`/gigs/${id}/deleteGig`);
export const commentGig = (value, id) =>
  API.post(`/user/user/commentGig/${id}`, { value });
export const updateProfile = (id, updatedProfile) =>
  API.put(`/gigs/profile/update/user/${id}`, updatedProfile);
export async function resetPassword({ email, password }) {
  try {
    const { data, status } = API.put("/user/updatePassword", {
      email,
      password,
    });
    return Promise.resolve({ data, status });
  } catch (error) {
    return Promise.reject({ error });
  }
}
export const createChat = (id) => API.post(`/${id}`);
export const fetchChats = (id) => API.get(`/${id}`);
export const signIn = (formData) => API.post("/user/signin", formData);
export const signUp = (formData) => API.post("/user/signup", formData);
