import { toast } from "react-toastify";
import * as api from "../api/index.js";
import {
  CREATE,
  UPDATE,
  LIKE,
  DELETE,
  FETCH_ALL,
  FETCH_GIG,
} from "../reducers/actionType/actionType.js";
export const getCategory = (cat) => async (dispatch) => {
  try {
    const { data } = await api.fetchCategory(cat);
    dispatch({ type: "FETCH_CATEGORY", payload: data });
  } catch (error) {
    console.log(error.message);
    toast.error(error.message);
  }
};
export const getPostsBySearch = (searchQuery) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await api.fetchPostsBySearch(searchQuery);
    console.log(data);
    // dispatch({ type: "FETCH_BY_SEARCH", payload: { data } });
  } catch (error) {
    console.log(error);
  }
};
export const getDetails = (id) => async (dispatch) => {
  try {
    const { data } = await api.fetchDetails(id);
    dispatch({ type: "FETCH_DETAILS", payload: data });
  } catch (error) {
    console.log(error.message);
    toast.error(error.message);
  }
};
export const getUserData = (id) => async (dispatch) => {
  try {
    const { data } = await api.fetchUserData(id);
    dispatch({ type: "FETCH_USERDATA", payload: data });
  } catch (error) {
    console.log(error.message);
    toast.error(error.message);
  }
};
export const updateProfile = (formData) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("token");
    const data = await api.updateProfile(formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({ type: UPDATE, data });
  } catch (error) {
    return Promise.reject({ error: "Couldn't Update Profile...!" });
  }
};
export const getGigs = () => async (dispatch) => {
  try {
    const { data } = await api.fetchGigs();

    dispatch({ type: "FETCH_ALL", payload: data });
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};

export const createGig = (post) => async (dispatch) => {
  try {
    const { data } = await api.createGig(post);

    dispatch({ type: "CREATE", payload: data });
  } catch (error) {
    console.log(error.message);
    toast.error(error.message);
  }
};

export const likeGig = (id) => async (dispatch) => {
  try {
    const { data } = await api.likeGig(id);

    dispatch({ type: "LIKE", payload: data });
  } catch (error) {
    console.log(error.message);
    toast.error(error.message);
  }
};

export const deleteGig = (id) => async (dispatch) => {
  try {
    await api.deleteGig(id);

    dispatch({ type: "DELETE", payload: id });
  } catch (error) {
    console.log(error.message);
    toast.error(error.message);
  }
};
export const commentGig = (value, id) => async (dispatch) => {
  try {
    const { data } = await api.commentGig(value, id);

    dispatch({ type: "COMMENT", payload: data });

    return data.comments;
  } catch (error) {
    console.log(error);
  }
};
