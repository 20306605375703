import { Avatar, CircularProgress, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteGig, likeGig } from "../../actions/index";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
const Categories = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const itemsPerPage = 12;
  const user = JSON.parse(localStorage.getItem("profile"));
  const [data, setData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const userId = user?.result?._id;
  const navigate = useNavigate();
  const [likes, setLikes] = useState(data?.likes);
  const hasLikedPost = true;
  const name = data?.name1 + " " + data?.name2;
  const { cat } = useParams();

  const fetchData = async () => {
    // console.log({trigger: 'triggered'})
    try {
      const response = await fetch(
        `https://masta-api-ae9y.onrender.com/user/search/user?page=${pageNumber}&itemsPerPage=${itemsPerPage}&query=${cat}`
      ); // Replace 'your_api_endpoint' with the actual API endpoint
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error scenarios (e.g., setting an error state)
      toast.error(error.message);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData(data);
  }, [cat, itemsPerPage, pageNumber]);
  const handleLike = async () => {
    dispatch(likeGig(data?._id));

    if (hasLikedPost) {
      setLikes(data?.likes?.filter((id) => id !== userId));
    } else {
      setLikes([...data?.likes, userId]);
    }
  };
  const opendelete = () => {
    setIsOpen(true);
  };
  const closedelete = () => {
    setIsOpen(false);
  };
  const deletegig = () => {
    dispatch(deleteGig(data?._id));
    setIsOpen(false);
  };
  const Likes = () => {
    // if (data?.likes.length > 0) {
    //   return likes.find((like) => like === userId)
    //     ? (
    //       <div className='text-[12px] lg:text-[20px] '><FavoriteIcon  />&nbsp;{data?.likes?.length > 2 ? `You and ${data?.likes?.length - 1} others` : `${data?.likes?.length} like${data?.likes?.length > 1 ? 's' : ''}` }
    //       </div>
    //     ) : (
    //       <div  className='text-[12px] lg:text-[20px] '><FavoriteBorderIcon  />&nbsp;{data?.likes?.length} {data?.likes?.length === 1 ? 'like' : 'likes'}</div>
    //     );
    // }

    return (
      <div className="text-[12px] lg:text-[20px] ">
        <FavoriteBorderIcon />
        &nbsp;like
      </div>
    );
  };
  if (data?.length === null) {
    return toast.error("This Categories does not exist Yet");
  }
  return (
    <>
      <div className="w-full bg-accent text-black  my-2 p-2 rounded-[8px] font-semibold   ">
        {data?.length === 0 ? (
          <div className=" flex justify-center items-center h-[50vh] font-bold ">
            <div className="flex items-center justify-center gap-1">
              <h1>
                No Result Found In this Category - <span className="text-[18px] md:text-[20px] lg:text-[25px] ">{cat}</span>{" "}
              </h1>
            </div>
          </div>
        ) : (
          <></>
        )}
        {/* {
      data?.length > 1 && <h2 >There   {data?.length >1 ? "are " : "is"}  {data?.length} {data?.length >1 ? "Services " : "Service"} in this Category </h2>
    }
   */}
      </div>

      <div className="relative flex flex-wrap  gap-2">
        {!data?.length ? (
          " "
        ) : (
          <>
            {data?.map((category) => (
              <div key={category?._id}>
                <div key={category?._id} className="relative cursor-pointer">
                  <div className="group grad lg:h-[450px]  h-[250px] w-[160px] rounded-[8px] lg:w-[300px]  max-w-[350px] overflow-hidden relative  hover:scale-[0.98] transition-all hover:shadow-gray-700 ">
                    <Link to={`/gigs/profile/${category?._id}`}>
                      <div className="relative flex items-center justify-center object-cover w-full">
                        <img
                          src={
                            category?.passport?.url
                              ? category?.passport?.url
                              : "https://toppng.com/uploads/preview/app-icon-set-login-icon-comments-avatar-icon-11553436380yill0nchdm.png"
                          }
                          alt={category?.firstName}
                          className="  w-[150px] h-[100px]  lg:w-[350px] lg:h-[200px]  flex justify-center items-center transition-all object-cover rounded-[5px] "
                        />
                      </div>
                    </Link>
                    {/* {
        user?.result && <>
        <div onClick={handleLike} className='fixed flex lg:hidden   cursor-pointer  text-[10px] lg:text-[20px]  text-accent bottom-12  left-0 font-meium items-center '  >
       <IconButton color="inherit">
          <Likes />
       </IconButton>
      </div>
      <div onClick={handleLike} className='fixed hidden lg:flex  text-[10px]   cursor-pointer lg:text-[20px]   text-accent bottom-12  left-0 font-meium items-center '  >
       <IconButton color='inherit'>
          <Likes/>
       </IconButton>
      </div>
        </>
      } */}
                    <Link to={`tel:${category.telephone}`}>
                      <div className=" cursor-pointer flex gap-2 text-[12px] lg:text-[20px] text-black justify-center p-3 w-full  mt-2  font-medium  items-center bg-accent fixed bottom-0 text-center ">
                        <p>Call Artisan </p>
                        <span className="">
                          <LocalPhoneIcon />
                        </span>
                      </div>
                    </Link>

                    <div className="pb-2  px-3  flex flex-col z-[7] mx-auto">
                      <div className="lg:text-[25px] bg-accent rounded-[8px] text-black p-2 text-[10px]   capitalize flex gap-2 mb-2 fixed top-0 left-0 z-[6]  font-semibold">
                        {category?.firstName} {category?.lastName}{" "}
                      </div>
                    </div>
                    <div className="text-[12px] lg:text-[20px] mt-0 lg:mt-4  z-10 ">
                      {/* {gig?.title.length>30 ? gig?.title.substring("...") : gig?.title } {} */}
                    </div>
                    <div className="text-[12px] lg:text-[25px] text-gray-300 ">{category.skill}</div>
                    <hr />
                    <div className="text-[12px] lg:text-[25px] text-gray-300 lg:mb-9">
                      {category.officeAddress}{" "}
                      <span className="text-[10px] ">{/* {gig?.subSkill  ? `: ${gig?.subSkill}  ` : null} */}</span>
                    </div>
                    <div className="fixed items-center bg-accent text-[10px] p-2 rounded-[8px] gap-1 left  bottom-[53%] md:bottom-[58%]  md:text-[12px]  ">
                      <span>
                        {" "}
                        <LocationOnIcon />
                      </span>
                      <span>
                        {category.officestate}, {category?.officeBusStop}
                      </span>
                    </div>
                  </div>
                  {isOpen && (
                    <div className="">
                      <div className="bg-primary  z-[8] flex flex-col items-center fixed bottom-0 w-[full]  rounded-[8px] p-5 ] ">
                        <h2 className="flex leading-tight">Are You Sure You want to delete</h2>
                        <div className="flex flex-row items-center gap-6 mb-1 font-medium justify-evenly">
                          <div
                            onClick={() => dispatch(deleteGig(category?._id))}
                            className="bg-accent text-black p-2 rounded-[8px] font-medium ">
                            <button className="tracking-wide ">YES</button>
                          </div>
                          <div onClick={closedelete} className="bg-accent text-black p-2 rounded-[8px] font-medium ">
                            <button className="tracking-wide ">NO</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default Categories;
