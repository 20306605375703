import React from 'react'
import Hero from './Hero';
import Products from '../../containers/Products';
import Provider from './Provider';
import Category from "./Category"
import Software from '../Category/Software';
import Interior from '../Category/Interior';
import Events from '../Category/Events';
import Aplliances from '../Category/Aplliances';

const Home = ({ setCurrentId }) => {
  const user = JSON.parse(localStorage.getItem("profile")) 
  
  return (
   <div className='mb-4'>
    <div className=''>
    <Hero/>
    </div> 
    <div>
      {/* Services */}
      <Provider/>
    </div>
    <div >
    <Products setCurrentId={setCurrentId}/>
    </div>
   <div className=' flex justify-center '>
    <Category/>
   </div>
   <div className=' mx-auto '>
    <Software/>
   </div>
   <div className=' mx-auto '>
    <Interior/>
   </div> 
      <div>
       <Aplliances/>
      </div>
   </div>
  )
}

export default Home