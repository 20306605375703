import React, { useEffect, useState } from 'react'

import Artisan from './Artisan'
import { toast } from 'react-toastify'
import SearchIcon from '@mui/icons-material/Search';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
const Admin = () => {
    const [data, setData] = useState(null);
  const [search, setSearch] = useState('');
  const [pageNumber, setPageNumber] = useState(1)
  const itemsPerPage = 10
  
  const fetchData = async () => {
    try {
      const response = await fetch(`https://masta-api-ae9y.onrender.com/user/search/user?page=${pageNumber}&itemsPerPage=${itemsPerPage}&query=${search}`); // Replace 'your_api_endpoint' with the actual API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result);
     
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error("Error fetching data:", error.message)
    }
  }; 
   useEffect(() => {
  fetchData(data)
   }, [pageNumber, itemsPerPage])
    const searchPost = (e)=> {
        e.preventDefault()
        if(search.trim()){
          fetchData(data)
        }
        else{
          
        }
      }
      
      const handleKeyPress = (e)=> {
      if(e.keycode === 13){
        searchPost()
      }
      }
  return (
    <div className="overflow-hidden">
        {/* Search Input*/}
        <div className='flex items-center justify-center p-2 bg-primary '>
          <div className=' w-full  xl:max-w-[734px] flex justify-center items-center'>
              <form onSubmit={searchPost}
              className={`w-[100%]  relative `}>
              <input
              type="text"
              className="input w-full  placeholder:text-[12px]  lg:placeholder:text-[18px] "
              placeholder="Search for Service, Repairs, Artisans..."
              value={search}
              onChange={(e)=> setSearch(e.target.value)}
              onKeyDown={handleKeyPress}
              />
              <button  className="absolute top-0 right-0 rounded-tl-none rounded-bl-none btn btn-accent ">
              <SearchIcon className="text-xl" />
              </button>
              </form>
          </div>
      </div>
        {/* Search Input*/}
        {data?.length===0 &&
       <div className='w-full bg-accent text-black  my-2 p-2 rounded-[8px] font-semibold   '>
    
    <div className=" flex justify-center  items-center h-[50vh] font-bold ">
     <div className='flex items-center gap-1 '>
       <h1>No Data Found<span className='text-[25px] '></span> </h1>
     </div>
   </div>
 </div>}
        <div className=' w-full h-[100%] '>
        {data?.map((user)=> (
           <Artisan key={user?._id} data={user} />
        ))}
        {data && 
        <div className='flex items-center justify-between px-10 my-3'>
          <div className='cursor-pointer  text-accent'>
            <button onClick={()=> setPageNumber(prevPage => prevPage -1)} disabled={pageNumber ===1} className='flex items-center gap-2'> 
            <p>Prev</p>
              <KeyboardBackspaceOutlinedIcon fontSize='large' color='inherit' />
            </button>
          </div>
          <div className='cursor-pointer  text-accent'>
          <button onClick={()=> setPageNumber(prevPage => prevPage + 1)} className='flex items-center gap-2'  disabled={ data.length === 0 }> 
            <p>Next</p>
<ArrowRightAltOutlinedIcon fontSize='large'/>
</button>
          </div>
          </div>
          }
        </div>
    </div>
  )
}

export default Admin