import { Avatar, CircularProgress, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteGig, likeGig } from '../../actions/index';
import DeleteIcon from '@mui/icons-material/Delete';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Gigs from '../../containers/Gigs';
const Aplliances = () => {
  const user = JSON.parse(localStorage.getItem('profile'));
    const [data, setData] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false)
    const fetchData = async () => {
        // console.log({trigger: 'triggered'})
        try {
          const response = await fetch(`https://masta-api-ae9y.onrender.com/gigs/category/Appliances%20&%20Electronics`); // Replace 'your_api_endpoint' with the actual API endpoint
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setData(result);
         
        
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle error scenarios (e.g., setting an error state)
          toast.error(error.message)
        }
      };
      useEffect(() => {
      fetchData(data)
      }, [])
      const opendelete =()=> {
        setIsOpen(true)
      }
      const closedelete =()=> {
        setIsOpen(false)
      }
      const deletegig =()=> {
        dispatch(deleteGig(data?._id))
        setIsOpen(false)
      }
  return (
    <div className='my-4 '>
      <div  className='flex items-center justify-center '>
        <h2 className=" mb-2 text-center px-10 py-[10px]  rounded-[5px] flex justify-center items-center text-black font-semibold text-[20px] lg:text-[25px] bg-accent   ">
        Appliances & Electronics
        </h2>
      
        </div>
      <Swiper 
      modules={[Pagination, Navigation]}
      loop={true}
      navigation={true}
      autoplay={true}
      style={{
        "--swiper-pagination-color": "#f64646",
        "--swiper-pagination-bullet-inactive-color": "#f64646",
        "--swiper-pagination-bullet-inactive-opacity": "1",
        "--swiper-pagination-bullet-size": "16px",
        "--swiper-pagination-bullet-horizontal-gap": "6px",
        "--swiper-navigation-color": "#7a1313",
      }}
      breakpoints={{
        320: {
          slidesPerView: 3,
          spaceBetween: 120,
          
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 90, 
          
        },
        1024: {
          slidesPerView: 4,
          spaceBetween:90,
        
        },
        1440: {
          slidesPerView: 4,
          spaceBetween: 90,
          
        },
      }}
      className='ml-3 productSlider max-w-[360px] lg:max-w-[1410px]'>
  
  {
!data?.length ?  
<div className='h-[50vh] flex justify-center items-center text-accent'>
<CircularProgress  color='inherit' size={"100px"} />
      </div>
 :
<>
{data?.map((category)=> (
    
<SwiperSlide  key={Math.random(category?._id)} >
<Gigs gig={category} key={category?._id}/>
  </SwiperSlide>
  
  ))}
</>
  }

    
  </Swiper>
    </div>
    
  )
}

export default Aplliances