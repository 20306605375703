import React, { useEffect, useState } from 'react'
import Listings from '../containers/Services'
import { Button, Container, Paper, TextField, Typography } from '@mui/material'
import FileBase from  "react-file-base64"
import { useDispatch, useSelector } from 'react-redux';

import {createGig, } from "../actions/index"
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const ArtisanGigs = ({ currentId, setCurrentId }) => {
  const user = JSON.parse(localStorage.getItem("profile"))
    const [gigData, setGigData] = useState({  title: '', prize: '', skills: '',subSkill: "", selectedFile: '', selectedFile2: "", passport: user?.result?.passport, telephone: user?.result?.telephone, gender:user?.result?.gender, Addresss:user?.result?.homeAddress, skill: user?.result?.skill, BusStop:user?.result?.homeBusStop,state:user?.result?.state,email:user?.result?.email,})
    const gig = useSelector((state) => (currentId ? state.gigs.find((message) => message._id === currentId) : null));
    const dispatch = useDispatch();
  const navigate = useNavigate()
  useEffect(() => {
    if (gig) setGigData(gig);
  }, [gig]);
   const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentId === 0) {
      dispatch(createGig({...gigData, name1: user?.result?.lastName, name2: user?.result?.firstName, skill: user?.result?.skill, passport: user?.result?.passport, telephone: user?.result?.telephone, state: user?.result?.state, homeAddress: user?.result?.homeAddress, homeBusStop: user?.result?.homeBusStop }));
    }  
    navigate("/")
    toast.success("Gigs Created Successfully")
  };
  if(!user?.result){
    return (
      <Paper>
        <Typography variant='h6' align='center'>
          Please Sign In to own a Gig and like acquire services 
        </Typography>
      </Paper>
    )
  }
  const clear = ()=> {
    setCurrentId(0);
    setGigData({  title: '', prize: '', skills: '', selectedFile:null, selectedFile2:null });
    toast.success("Data Clear successfully")
  }
  return (
            <Paper className='m-[16px]'>
          <form autoComplete='off' noValidate className={"m-2 flex flex-col flex-wrap justify-center"} onSubmit={handleSubmit}>
                  <Typography variant="h4">Creating a Gig</Typography>
                  <div className='mb-3 w-full '>
                  <TextField className='my-1' name="title" variant="outlined" label="Title"  fullWidth value={gigData.title} onChange={(e) => setGigData({ ...gigData, title: e.target.value })} />
                  </div>
                  <div  className='mb-3 w-full '>
                  <TextField className='w-[100%] my-[10px]' name="message" variant="outlined" label="Price" fullWidth  value={gigData.prize} onChange={(e) => setGigData({...gigData, prize: e.target.value })} />
                  </div>
                  <div className='w-[100%]  overflow-hidden  max-h-[300px] mb-2 outline-none border-none'>
                    <select defaultValue={"DEFAULT"} className='w-[100%]  max-h-[100px] p-[8px] text-[16px] rounded-[5px] border-[1px] border-[#333]  outline-none  overflow-hidden border-gray-[200]' onChange={(e) => setGigData({...gigData, skills: e.target.value })} >
                    <option disabled value="DEFAULT" className="text-acccent"   >Select a Profession</option>
                    {
                          ["A.C & Refrigeration Services", "Aluminium & Glass", "Appliances & Electronics","AUTO - Mechanic & Rewire & Panel", "Barber", "Beautician", "Brick Layer & Tiller & POP", "Carpentary Services", "Catering Services", "Cleaning & Laundary & Fumigation", "Dispatch Rider", " DSTV & CCTV", "Electrical & Inverter Services", "HairStylist", "Haulage & Movers", "Home Interior Fitting", "Make-Up Artist", "Painter & Screeder & Wallpaper" , " Phone & Laptop", "Photographer & Video", "Plumbing Services", "Real Estate Realtor", "Testing-Official Use", "Vehicle Towing", "Welder Services" ].map((skill, id)=>  (
                            <option value={skill}  key={id}  className='bg-body  text-gray-400 overflow-hidden h-fit hover:bg-accent  outline-none'>
                                {skill}
                            </option>
                          )
                          ) 
                    }
                    </select>                  
                  </div>
              <TextField name="s" variant="outlined" label="SubSkill" fullWidth value={gigData.subSkill} onChange={(e) => setGigData({ ...gigData, subSkill: e.target.value })} />
              <div className='flex gap-2 justify-between ml-6 flex-col lg:flex-row '>
              <div className="w-[100%] my-[10px] mx-0 flex gap-2 items-center"><label className=' font-medium'>Image1:</label> <FileBase type="file" multiple={false} onDone={({ base64 }) => setGigData({ ...gigData, selectedFile: base64 })} /></div>
              <div className="w-[100%] my-[10px] mx-0 flex gap-2 items-center"> <label className=' font-medium'>Image2:</label> <FileBase type="file" multiple={false} onDone={({ base64 }) => setGigData({ ...gigData, selectedFile2: base64 })} /></div>
              </div>  
              <div className='my-2 w-[100%] '><Button className='my-5' variant="contained" color="primary" size="large" type="submit" fullWidth>Submit</Button></div>
              <div className='my-2 w-[100%] '><Button onClick={clear} className='my-5 ' variant="contained" color="secondary" size="small"  fullWidth>Clear</Button></div>
        </form>
         </Paper>
      
  )
}

export default ArtisanGigs
