import React, { useState } from "react";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton } from "@mui/material";
const Listings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const OpenCat = () => {
    setIsOpen(!isOpen);
  };
  const hideCat = () => {
    setIsOpen(false);
  };
  return (
    <div className="h-[700px] max-h-[400px]   p-2 ">
      {/* <div onClick={OpenCat} className='flex lg:hidden z-[7] text-accent justify-center items-center '>
        <IconButton color='inherit'> <ArrowForwardIosIcon/></IconButton>
      </div> */}
      <div className="">
        <div className="bg-primary flex flex-col w-[100px] lg:w-[286px] max-h-[400px] rounded-[8px] overflow-hidden sticky top-0 h-fit">
          <div className="bg-accent py-4  text-primary uppercase font-semibold  items-center justify-center hidden lg:flex ">
            Browse Services
          </div>
          <div className="flex flex-col gap-y-4 p-6  overflow-y-auto category">
            {[
              "A.C & Refrigeration Services",
              "Aluminium & Glass",
              "Appliances & Electronics",
              "AUTO - Mechanic & Rewire & Panel",
              "Barber",
              "Beautician",
              "Brick Layer & Tiller & POP",
              "Carpentary Services",
              "Catering Services",
              "Cleaning & Laundary & Fumigation",
              "Dispatch Rider",
              " DSTV & CCTV",
              "Electrical & Inverter Services",
              "Fashion Designer",
              "HairStylist",
              "Haulage & Movers",
              "Home Interior Fitting",
              "Make-Up Artist",
              "Painter & Screeder & Wallpaper",
              " Phone & Laptop",
              "Photographer & Video",
              "Plumbing Services",
              "Real Estate Realtor",
              "Testing-Official Use",
              "Vehicle Towing",
              "Welder Services",
            ].map((list, index) => (
              <div className=" flex flex-col capitalize" key={Math.random(list)}>
                <Link
                  key={index}
                  to={`/gigs/category/${list}`}
                  className="cursor-pointer uppercase flex text-[10px] lg:text-[15px]  justify-between items-center hover:text-accent-hover">
                  <div>{list}</div>
                  <div className="hidden lg:flex ">
                    <ArrowForwardIosIcon />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* {
      isOpen && <div className=' lg:hidden text-[25px]   flex z-30   flex-col bg-body p-2  h-[100%]  relative'>
      <div className=' z-[9999999 ] fixed top-10   bg-body  text-accent  p-3 h-[100vh]  w-[100vh] '>
        <div className='mt-[20px] '>
        {
 ["Cleaning", "Automobile", "Fashion", "Services & Repairs", "Mansory & Craftman", "Home & Interior","Health Services", "Software Developement", "Others..."  ].map((list, index)=> (

  <Link key={index} to={`/gigs/category/${list}`}  onClick={hideCat} className='cursor-pointer uppercase flex mt-[10px] text-[15px]  justify-between items-center hover:text-accent-hover'>
    <div>
    {list}
    </div>
  </Link>
 ))
      }
        </div>
     
      </div>
      </div>
    } */}
    </div>
  );
};

export default Listings;
