import React, { useEffect, useState } from 'react'
import {
  Button,
  Typography,
  Grid,
  Container,
  Paper,

} from "@mui/material";
import FileBase from "react-file-base64";
import Masta from "../../images/MASTA-logo.png";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Input from "./UpdateInput/Input";

import { toast } from 'react-toastify';
import { updateProfile } from '../../actions/index';
import axios from 'axios';

const UpdateAuth = ({currentId}) => {
  const {id} = useParams()

  const user = JSON.parse(localStorage.getItem("profile"))
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    confirmPassword: "",
    password: "",
    telephone: "",
    gender: "",
    skill: "",
    nationality: "",
    passport: "",
    dob: "",
    education: "",
    homeAddress: "",
    homeBusStop: "",
    state: "",
    officeAddress: "",
    officeBusStop: "",
    idName: "",
    nin: "",
    issuedate: "",
    expiryDate: "",
    idTelephone: "",
    maritalStatus: "",
    officestate: "", 
    officetelephone: "",
    kinaddress: "",
    kinname:"",
    kingender: "",
    kinemail: "",
    kintelephone: ""
  };
  const [showPassword, setShowPassword] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [mode, setMode] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const switchMode = () => {
    setIsSignUp((prev) => !prev);
    handleShowPassword(false);
  };
  const handleChange = (e) => {
    const {name} = e.target.value
   setFormData({...formData, [e.target.name]: e.target.value})
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault()
   try {
   await axios.put(`https://masta-api-ae9y.onrender.com/user/user/editUser/${id}` , formData
    )
    
    
    console.log(formData)

   } catch (error) {

   }
    
    navigate(`/gigs/profile/${id}`)
  };
  
  const buyer = () => {
    setMode(true)
  };
  const seller = () => {
    setMode(false)
  };

 

  return (
    <Container className="">
      <Paper
        className={
          "mt-[64px] bg-primary mb-[10px] flex flex-col items-center p-[8px]   justify-center "
        }>
        
        <div className="flex items-center justify-center ">
          <img
            src={Masta}
            alt="Logo"
            className="w-[30%] lg:w-[20%]  object-cover"
          />
        </div>

        <Typography variant="h5">
          {" "}
          {"Update Profile"}
        </Typography>
        {
           <div className="flex justify-center items-center mt-[10px] ">
          <div className="flex items-center gap-4">
            <Button  onClick={seller}
              fullWidth
              variant="contained"
              color="primary">Buyer</Button>
            <Button  onClick={buyer}
              fullWidth
              variant="contained"
              color="primary">Seller</Button>
          </div>
          
         </div>
        }
       
        <form className={"w-[100%] mt-[24px] "} onSubmit={handleSubmit}>
          <h2
            className="text-[25px] font-medium  uppercase mb-3"
            color="primary">
            {" Personal Details"  }
          </h2>
          <Grid container spacing={2}>
           
              <>
                <Input
                  name="firstName"
                  label="First Name"
                  handleChange={handleChange}
                  autoFocus
                  half
                />
                <Input
                  name="lastName"
                  label="Last Name"
                  handleChange={handleChange}
                  half
                />

                <Input
                  name={"gender"}
                  label={"Gender"}
                  handleChange={handleChange}
                  half
                />
                <Input
                  name={"nationality"}
                  label={"Nationality"}
                  handleChange={handleChange}
                  half
                />
                <>
                {mode && <Input
                  name={"maritalStatus"}
                  label={"Marital Status"}
                  handleChange={handleChange}
                  half
                /> }
                </>
                
                <> {
                  mode && 
                  <Input
                  name={"skills"}
                  label={"Skills (Please Specify)"}
                  handleChange={handleChange}
                  half
                />
                }
                
                </>
               
                <Input
                  name={"dob"}
                  label={"Date of Birth"}
                  handleChange={handleChange}
                  half
                  
                />
                <div className=" ml-4 lg:ml-6 mt-[10px]  flex items-center gap-3 ">
                  <div className="text-[16px] lg:text-[20px] font-medium text-gray-500">
                    Passport:{" "}
                  </div>
                  <FileBase type="file" multiple={false} onDone={({ base64 }) => setFormData({ ...formData, passport: base64 })} />
                </div>
              </>
           

            <Input
              name={"email"}
              label={"Email Address"}
              handleChange={handleChange}
              type={"email"}
              half
            />
          
              <>
                <Input
                  name={"telephone"}
                  label={"Telephone Number"}
                  handleChange={handleChange}
                  type={"tel"}
                  half
                />
                <>
                {mode && 
                <Input
                name={"education"}
                label={"Education Level (JSSCE, SSCE, OND, NCE, BSC)"}
                handleChange={handleChange}
              />
                }
                
                </>
               
              </>
          

            <Input
              name={"password"}
              label={"Password"}
              handleChange={handleChange}
              type={showPassword ? "text" : "password"}
              handleShowPassword={handleShowPassword}
              half
            />

            
              <>
                <Input
                  name={"confirmPassword"}
                  label={"Confirm Password"}
                  handleChange={handleChange}
                  type={"password"}
                  half
                />
              </>
        
            { <>
            
            {/* Address Information */}
          
              <>
                <h2
                  className="text-[25px] ml-6 mt-2   justify-center flex items-center   uppercase font-medium "
                  color="primary">
                  Address Information
                </h2>
                <Input
                  name={"homeAddress"}
                  label={"Home Address"}
                  handleChange={handleChange}
                />
                <Input
                  name={"homeBusStop"}
                  label={"Nearest Bus Stop"}
                  handleChange={handleChange}
                  half
                />
                <Input
                  name={"state"}
                  label={"State"}
                  handleChange={handleChange}
                  half
                />

                {
                  mode && 
                  <>
                  <Input
                  name={"officeAddress"}
                  label={"Office Address"}
                  handleChange={handleChange}
                />
                <Input
                  name={"officeBusStop"}
                  label={"Office  Nearest Bus Stop"}
                  handleChange={handleChange}
                  half
                />
                <Input
                  name={"officestate"}
                  label={"State"}
                  handleChange={handleChange}
                  half
                /></>
                }
                
              </>
           
            </>}
           {mode && <>
           
            {/* Nin Details */}
            
              <>
                <h2
                  className="text-[25px] ml-6 mt-2   justify-center flex items-center   uppercase font-medium "
                  color="primary">
                  nin Details
                </h2>
                <Input
                  name={"idName"}
                  label={"ID Name"}
                  handleChange={handleChange}
                />
                <Input
                  name={"nin"}
                  label={"ID Number"}
                  handleChange={handleChange}
                  half
                />
                <Input
                  name={"issuedate"}
                  label={"Issue Date"}
                  handleChange={handleChange}
                  half
                />
                <Input
                  name={"expirydate"}
                  label={"Expiry Date"}
                  handleChange={handleChange}
                  type={"email"}
                  half
                />
                <Input
                  name={"officetelephone"}
                  label={"Telephone Number"}
                  handleChange={handleChange}
                  half
                />
              </>
          
           </>}
            
           {
            mode && <>
             {/* Next of Kin */}
             
              <>
                <h2
                  className="text-[25px] ml-6 mt-2   justify-center flex items-center   uppercase font-medium "
                  color="primary">
                  next of kin
                </h2>
                <Input
                  name={"kinaddress"}
                  label={"Address"}
                  handleChange={handleChange}
                />
                <Input
                  name={"kinname"}
                  label={"Full Name"}
                  handleChange={handleChange}
                  half
                />
                <Input
                  name={"kingender"}
                  label={"Gender"}
                  handleChange={handleChange}
                  half
                />
                <Input
                  name={"kinemail"}
                  label={"Email Address"}
                  handleChange={handleChange}
                  type={"email"}
                  half
                />
                <Input
                  name={"kintelephone"}
                  label={"Telephone Number"}
                  handleChange={handleChange}
                  half
                />
              </>
          
            </>
           }
          </Grid>
          <div className="mt-3 w-[100%] ">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={"mt-5 bg-accent"}>
              {"Update Profile"}
            </Button>
          </div>
          
          
        </form>
      </Paper>
    </Container>
  )
}

export default UpdateAuth