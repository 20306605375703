import React from 'react'
import { Link } from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const Category = () => {
  return (
    <div className='flex w-full lg:hidden'>
       <div className='bg-primary flex flex-col max-h-[400px] rounded-[8px] overflow-hidden sticky top-0 h-fit w-[100%] '>
   <div className="items-center justify-center py-4 font-semibold uppercase bg-accent text-primary lg:flex  text-[12px] text-center">
          Browse Services 
        </div>
        <div className='flex flex-col p-6 overflow-y-auto gap-y-4 category'>
        {
 ["A.C & Refrigeration Services", "Aluminium & Glass", "Appliances & Electronics","AUTO - Mechanic & Rewire & Panel", "Barber", "Beautician", "Brick Layer & Tiller & POP", "Carpentary Services", "Catering Services", "Cleaning & Laundary & Fumigation", "Dispatch Rider", " DSTV & CCTV", "Electrical & Inverter Services" ,"Fashion Designer", "HairStylist", "Haulage & Movers", "Home Interior Fitting", "Make-Up Artist", "Painter & Screeder & Wallpaper" , " Phone & Laptop", "Photographer & Video", "Plumbing Services", "Real Estate Realtor", "Testing-Official Use", "Vehicle Towing", "Welder Services" ].map((list, index)=> (
  <div className='flex flex-col capitalize ' key={Math.random(list)}>
 <Link key={index} to={`/gigs/category/${list}`} className='cursor-pointer uppercase flex text-[10px] lg:text-[15px]  justify-between items-center hover:text-accent-hover'>
    <div>
    {list}
    </div>
    <div className='hidden lg:flex '>
      <ArrowForwardIosIcon/>
    </div>
  </Link>
  </div>
 
 ))
      }
        </div>
    </div>
    </div>
  )
}

export default Category
