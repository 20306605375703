import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import Gigs from '../../containers/Gigs';
import Artisan from '../Admin/Artisan';
const ListingGigs = ({setCurrentId}) => {
    const gigs  = useSelector((state) => state.gigs);
    useEffect(()=> {

    },[setCurrentId])
  return (
    <div  className='flex flex-wrap items-center w-full gap-2 category '>
        {
            !gigs?.length ? <CircularProgress  color='inherit'/>  : (
                <>
                {
                    gigs?.map((gig, id)=> (
                        <div key={id}   className='flex flex-wrap items-center gap-2 '>
                             <Artisan data={gig} setCurrentId={setCurrentId}  className='flex flex-wrap items-center gap-2 '/>
                        </div>
                    ))
                }
                </>
            )
        }
    </div>
  )
}

export default ListingGigs