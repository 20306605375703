import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import { thunk } from "redux-thunk";
import { reducers } from "./reducers";
import App from "./App";
import { thunk } from "redux-thunk";
import { Provider } from "react-redux";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { applyMiddleware, compose, createStore } from "redux";
import { BrowserRouter } from "react-router-dom";

const store = createStore(reducers, compose(applyMiddleware(thunk)));
const root = ReactDOM.createRoot(document.getElementById("root"));
function AlbumsGlimmer() {
  return (
    <div className="h-[100vh] z-20 ">
      <div className="glimmer-panel">
        <div className="glimmer-line" />
        <div className="glimmer-line" />
        <div className="glimmer-line" />
      </div>
    </div>
  );
}
root.render(
  <Suspense fallback={<AlbumsGlimmer />}>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </Suspense>
);
