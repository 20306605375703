import { Avatar, IconButton } from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { deleteGig, likeGig } from "../actions/index";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
const Gigs = ({ gig, setCurrentId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [likes, setLikes] = useState(gig?.likes);

  const user = JSON.parse(localStorage.getItem("profile"));

  const userId = user?.result?._id;

  const hasLikedPost = gig?.likes?.find((like) => like === userId);
  const name = gig.firstName + " " + gig.lastName;
  const handleLike = async () => {
    dispatch(likeGig(gig._id));

    if (hasLikedPost) {
      setLikes(gig?.likes.filter((id) => id !== userId));
    } else {
      setLikes([...gig?.likes, userId]);
    }
  };
  const opendelete = () => {
    setIsOpen(true);
  };
  const closedelete = () => {
    setIsOpen(false);
  };
  const deletegig = () => {
    dispatch(deleteGig(gig?._id));
    setIsOpen(false);
  };
  const Likes = () => {
    if (gig?.likes?.length > 0) {
      return likes?.find((like) => like === userId) ? (
        <div className="text-[12px] lg:text-[20px] ">
          <FavoriteIcon />
          &nbsp;
          {gig?.likes?.length > 2
            ? `You and ${gig?.likes?.length - 1} others`
            : `${gig?.likes?.length} like${gig?.likes?.length > 1 ? "s" : ""}`}
        </div>
      ) : (
        <div className="text-[12px] lg:text-[20px] ">
          <FavoriteBorderIcon />
          &nbsp;{gig?.likes?.length} {gig?.likes?.length === 1 ? "like" : "likes"}
        </div>
      );
    }

    return (
      <div className="text-[12px] lg:text-[20px] ">
        <FavoriteBorderIcon />
        &nbsp;like
      </div>
    );
  };

  const openGig = () => {
    navigate(`/gigs/profile/${gig?._id}`);
    window.location.reload(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className="relative cursor-pointer ">
      <div className="group grad lg:h-[450px]  h-[250px] w-[150px] rounded-[8px] lg:w-[350px]  max-w-[350px] overflow-hidden relative  hover:scale-[0.98] transition-all hover:shadow-gray-700 ">
        <div onClick={openGig} className="relative flex items-center justify-center object-cover w-full">
          <img
            src={
              gig?.passport?.url
                ? gig?.passport?.url
                : "https://toppng.com/uploads/preview/app-icon-set-login-icon-comments-avatar-icon-11553436380yill0nchdm.png"
            }
            alt={gig?.firstName}
            className="  w-[150px] h-[100px]  lg:w-[350px] lg:h-[200px]  flex justify-center items-center transition-all object-cover rounded-[5px] "
          />
        </div>

        {user?.result && (
          <>
            <div
              onClick={handleLike}
              className="fixed flex lg:hidden   cursor-pointer  text-[10px] lg:text-[20px]  text-accent bottom-12  left-0 font-meium items-center ">
              <IconButton color="inherit">
                <Likes />
              </IconButton>
            </div>
            <div
              onClick={handleLike}
              className="fixed hidden lg:flex  text-[10px]   cursor-pointer lg:text-[20px]   text-accent bottom-12  left-0 font-meium items-center ">
              <IconButton color="inherit">
                <Likes />
              </IconButton>
            </div>
          </>
        )}
        <Link to={`tel:${gig.telephone}`}>
          <div className=" cursor-pointer flex gap-2 text-[12px] lg:text-[20px] text-black justify-center p-3 w-full  mt-2  font-medium  items-center bg-accent fixed bottom-0 text-center ">
            <p>Call Artisan </p>
            <span className="">
              <LocalPhoneIcon />
            </span>
          </div>
        </Link>

        <div className="pb-2  px-3  flex flex-col z-[7] mx-auto">
          <div className="lg:text-[25px] bg-accent rounded-[8px] text-black p-2 text-[10px]   capitalize flex gap-2 mb-2 fixed top-0 left-0 z-[6]  font-semibold">
            {name}
          </div>
        </div>
        <div className="text-[12px] lg:text-[20px] mt-0 lg:mt-4  z-10 ">
          {/* {gig?.title.length>30 ? gig?.title.substring("...") : gig?.title } {} */}
        </div>
        <div className="text-[12px] lg:text-[25px] text-gray-300 ">{gig.skill}</div>
        <hr />
        <div className="text-[12px] lg:text-[25px] text-gray-300 lg:mb-9">
          {gig.officeAddress} <span className="text-[10px] ">{/* {gig?.subSkill  ? `: ${gig?.subSkill}  ` : null} */}</span>
        </div>
        <div className="fixed items-center bg-accent text-[10px] p-2 rounded-[8px] gap-1 left  bottom-[53%] md:bottom-[58%]  md:text-[12px]  ">
          <span>
            {" "}
            <LocationOnIcon />
          </span>
          <span>
            {gig.officestate} , {gig?.officeBusStop}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Gigs;
