import React, { useEffect, useState } from 'react'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const Artisan = ({data}) => {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
 


  return (
    <> 
    {
      !data ? 
      <div className='bg-accent h-[50px] '>
        <h2 className='text-white'> No </h2>
      </div>
        
 :  <div className='mt-4 cursor-pointer border-[1px] border-[whitesmoke] px-4 rounded-[5px]  hover:opacity-80 hover:scale-[1.01] transition-all py-2 w-full overflow-hidden'>
     
       
      <div className='flex items-center justify-between w-full'>
      </div>
       <div key={data?._id} onClick={()=> {navigate(`/gigs/profile/${data?._id}`)}} className='flex items-center justify-between w-full '>
        <div  onClick={()=> {navigate(`/gigs/profile/${data?._id}`)}} className='flex items-center gap-3'>
          <img src={data?.passport?.url ? data?.passport?.url : "https://toppng.com/uploads/preview/app-icon-set-login-icon-comments-avatar-icon-11553436380yill0nchdm.png"} alt={data?.firstName} className='h-[100px] w-[100px] lg:h-[200px]  lg:w-[200px] object-cover rounded-[50%] ' />
               <div className='flex flex-col text-[12px] lg:text-[16px]  '>
                <p className='text-accent  text-[14px] lg:text-[18px] '>{data?.firstName} {" "}  {data?.lastName}</p>
                <p>{data?.email}</p>
                <p>{data?.telephone}</p>
                <p>{data?.gender}</p>
                <p>{data?.skill}</p>
                <p  className='bg-accent text-black p-2 font-medium w-full rounded-[4px] '> <span className='text-white'><LocationOnOutlinedIcon color='inherit' fontSize='small'/></span>  {data?.officeBusStop}, {" "} {data?.officestate} </p>
               </div>
               </div>
               {/* Delete */}
            </div>
    </div>
    }
    
    </>
   
  )
}

export default Artisan