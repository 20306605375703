import React from "react";
import Service from "../../containers/Services";
import Filter from "../../components/Filter";
import Categories from "./Categories";
const Services = () => {
  return (
    <div className="flex justify-between gap-2 mx-3 my-3 ">
      <div className="flex-1  lg:flex-[0.2] sticky top-0 h-fit z-[6]  hidden lg:flex ">
        <Service />
      </div>
      <div className=" flex-1 lg:flex-[0.5]">
        <Categories />
      </div>
      <div className="flex-1  lg:flex-[0.3] hidden lg:flex   sticky h-fit top-0 z-[6]  bg-primary  p-2 rounded-[5px] ">
        <Filter />
      </div>
    </div>
  );
};

export default Services;
