import { toast } from "react-toastify";
import * as api from "../api/index";
import { AUTH, UPDATE } from "../reducers/actionType/actionType";

export const signin = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.signIn(formData);

    dispatch({ type: AUTH, data });
    router("/");
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};

export const signup = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.signUp(formData);

    dispatch({ type: AUTH, data });
    router("/");
    toast.success("Registration Sucessfull");
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};

export const resetPassword = async ({ email, password }) => {
  try {
    const { data, status } = await api.resetPassword({ email, password });
    return Promise.resolve({ data, status });
  } catch (error) {
    return Promise.reject({ error });
  }
};
export const likeGig = (id) => async (dispatch) => {
  try {
    const { data } = await api.likeGig(id);

    dispatch({ type: "LIKE", payload: data });
  } catch (error) {
    console.log(error.message);
    toast.error(error.message);
  }
};
export const commentGig = (value, id) => async (dispatch) => {
  try {
    const { data } = await api.commentGig(value, id);

    dispatch({ type: "COMMENT", payload: data });

    return data.comments;
  } catch (error) {
    console.log(error);
  }
};