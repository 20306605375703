import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import Gigs from './Gigs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

const Products = ({setCurrentId}) => {
    const gigs  = useSelector((state) => state.gigs);
    useEffect(()=> {

    },[setCurrentId])
    
  return (
    <div>
          <div className="h2 mb-6 text-center md:text-left">Recommended For You </div>
        <Swiper modules={[Pagination, Navigation]}
      loop={true}
      navigation={true}
      autoplay={true}
      style={{
        "--swiper-pagination-color": "#f64646",
        "--swiper-pagination-bullet-inactive-color": "#f64646",
        "--swiper-pagination-bullet-inactive-opacity": "1",
        "--swiper-pagination-bullet-size": "16px",
        "--swiper-pagination-bullet-horizontal-gap": "6px",
        "--swiper-navigation-color": "#7a1313",
      }}
      breakpoints={{
        320: {
          slidesPerView: 3,
          spaceBetween: 120,
          
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 90, 
          
        },
        1024: {
          slidesPerView: 4,
          spaceBetween:90,
        
        },
        1440: {
          slidesPerView: 4,
          spaceBetween: 90,
          
        },
      }}
      
       className='ml-3 productSlider max-w-[360px] lg:max-w-[1410px]'>
        {
      !gigs?.length ? <div className="flex justify-center items-center  text-accent">
<CircularProgress  color='inherit'  size={"100px"}/>
      </div>  : (
        < >
           {gigs?.map((gig, id)=> (
            <SwiperSlide  key={Math.random(id)} >
              <Gigs  gig={gig} setCurrentId={setCurrentId}/>
            </SwiperSlide>
           ))}
        </>
      )
 } 
        </Swiper>
         
    </div>
  )
}

export default Products