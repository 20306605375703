import React from "react";
import Service from "../../containers/Services";

import ListingGigs from "./ListingGigs";

const Listings = ({ setCurrentId }) => {
  return (
    <div className="flex justify-between gap-2 my-3 ">
      <div className=" sticky top-0 h-fit z-[6]  ">
        <Service />
      </div>
      <div className="  w-[100%] sticky h-fit  max-h-[500px] category overflow-y-auto  flex-1 ">
        <ListingGigs setCurrentId={setCurrentId} />
      </div>
      <div></div>
    </div>
  );
};

export default Listings;
