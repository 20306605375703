import React from 'react'
import Logo from  "../../images/logo2.png"
import AppleIcon from '@mui/icons-material/Apple';
import ShopIcon from '@mui/icons-material/Shop';
import { Link } from 'react-router-dom';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import { IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const Footer = () => {
  return (
    <div className='flex flex-col p-4 mb-1 overflow-hidden text-white bg-primary opacity-90'>      
    <div className='flex flex-col items-center justify-between mb-3 lg:flex-row '>
      {/* NewsLetter */}

      <div className='flex items-center justify-between '>
        {/* Logo */}
    <div className='flex flex-col p-2 '>
      <div className='w-[100%] '>
      <img src={Logo} alt="" width={"50%"} />
      </div>
    <div className='text-white'>
      <p>MASTA is always fostering seamless <br /> connections and enhanced customer experiences.</p>
    </div>
    </div>
      </div>
      {/*  */}
      <div className='w-[100%] '>
      <h2 className=' font-bold my-2 text-[20px] '>Subscribe To Our Newsletter!</h2>
      <div className='flex  w-[100%]  border-gray-700  bg-slate-950 border-[1px] p-1 rounded-[3px]  '>
      <form 
      className={`w-[100%]  relative  flex `}>
      <input
        type="text"
        className=" outline-none rounded-[8px] w-full px-5 py-4 text-black " 
        placeholder="Search for Service, Repairs, Artisans..."
      
      />
      
      <button  className="flex justify-center items-center rounded-[8px] px-10 py-[10px] text-sm uppercase font-bold bg-accent text-primary hover:bg-accent-hover transition-all -ml-3 " >
        Subscribe
      </button>
    </form>
      </div>
       
      </div>
    </div>

    {/* Links */}
    <div className='container flex flex-wrap gap-2 mx-auto '>
      <div className='flex flex-wrap items-center justify-between gap-5 '>
      {/* App Download  */}
      <div className='flex flex-col '>
        <h2 className=' border-b-[1px] border-gray-600 my-3 font-bold space-x-2'>Get Our App </h2>
      <div className='flex items-center gap-2 '>
      <div className='flex justify-between items-center gap-2 bg-gray-700 p-3 rounded-[5px] '>
        <div>
        <AppleIcon fontSize='large'/>
        </div>
          
          <div className='flex flex-col text-[15px] '>
            <p>Download on </p>
            <p>App Store</p>
          </div>

      </div>
      <div className='flex justify-between items-center gap-2 bg-gray-700 p-3 rounded-[5px] flex-wrap '>
        <div>
        <ShopIcon fontSize='large'/>
        </div>
          
          <div className='flex flex-col text-[12px] md:text-[15px]  gap-1 '>
            <p>Download on </p>
            <p>Google Play Store</p>
          </div>

      </div>
      </div>
       {/* Phone */}
      <div className='flex   items-center gap-4 bg-gray-700 my-3 p-2  rounded-[5px] '>
        <div className='opacity-100 text-accent'>
        <PhoneForwardedIcon fontSize='large' color='#ea580c'/>
        </div>
        <div className='flex flex-col text-gray-500 '>
          <h3 className='capitalize '>Phone</h3>
          <p className='text-white'>08088170119</p>
        </div>
   
      </div>
      </div>

      {/* Links */}
      {/* Explore */}
      <div className='flex flex-wrap items-center justify-between gap-10 mx-auto lg:gap-3 lg:justify-center'>
      <div className='flex flex-col '>
    <h2 className='border-b-[1px] border-gray-600 my-3 text-[20px] pr-24 '>Explore</h2>
    <div className='flex flex-col text-gray-600 transition-all'>
      {
        ["My Dashboard", "Submit-Listing", "Login","Register", "Our News", "Contact Us"].map((link)  =>  (
          <Link key={link} className='text-[16px] my-2    hover:text-white' to={link}>{link} </Link>
        ))
      }
     
    </div>
      </div>
      {/*Top Categories */}
      <div className='flex flex-col '>
    <h2 className=' flex gap-2 border-b-[1px] border-gray-600 my-3 text-[18px] pr-24 '><p>Top</p> <p>Categories</p> </h2>
    <div className='flex flex-col text-gray-600 transition-all'>
      {
        ["Plumbing", "Mechanics", "Painting","Panel Beater ", "Electrician", "Vulcanizer"].map((link)  =>  (
          <Link key={link} className='text-[16px]  my-2   hover:text-white' to={`/gigs/category/${link}`}>{link} </Link>
        ))
      }
     
    </div>
      </div>
      {/* Top LOACTIONS */}
      <div className='flex flex-col '>
      <h2 className=' flex gap-2 border-b-[1px] border-gray-600 my-3 text-[18px] pr-24 '><p>Top</p> <p>Locations</p> </h2>
    <div className='flex flex-col text-gray-600 transition-all'>
      {
        ["Agege", "Epe", "Eti-Osa","Apapa", "Badagry", ].map((link)  =>  (
          <Link key={link} className=' text-[16px] my-2  hover:text-white' to={link}>{link} </Link>
        ))
      }
     
    </div>
      </div>
      {/* Important Links */}
      <div className='flex flex-col '>
      <h2 className=' flex gap-2 border-b-[1px] border-gray-600 my-3 text-[18px] pr-24 '><p>Important </p> <p>Links</p> </h2>
    <div className='flex flex-col text-gray-600 transition-all'>
      {
        ["Help Desk", "About", "Refund and Return Policy","Terms of Service", "Site Map", "Posting Ploicy" ].map((link)  =>  (
          <Link key={link} className='text-[16px]  my-2  hover:text-white' to={link}>{link} </Link>
        ))
      }
     
    </div>
      </div>
      
      </div>
    </div>
    </div>
    <hr />
    <div className='flex flex-col items-center justify-between mt-3 lg:flex-row'>
      <div className='text-[12px] lg:text-[25px] '>
        &copy; Copyright 2023 by masta.ng
       
      </div>
      
      {/* Social Icons */}
      <div className='flex items-center gap-2 justify-evenly text-accent '>
      <IconButton color="inherit"  className='hover:text-accent-hover'>
      <FacebookIcon />
      </IconButton>
      <IconButton color="inherit" className='hover:text-accent-hover'>
      <TwitterIcon/>
      </IconButton>
      <IconButton color="inherit" className='hover:text-accent-hover'>
      <InstagramIcon/>
      </IconButton>
      <IconButton color="inherit" className='hover:text-accent-hover'>
      <LinkedInIcon/>
      </IconButton>
      </div>
    </div>
    </div>
  )
}

export default Footer