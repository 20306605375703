import React, { useEffect, useState } from "react";
import Logo from "../../images/logo2.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { jwtDecode as decode } from "jwt-decode";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("Profile")));
  const handleMenu = () => {
    setIsOpen(true);
  };
  const hideMenu = () => {
    setIsOpen(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(()=> {
  //   window.scrollTo(0,0);
  //   fetchData(data)
  //   }, [data])

  const logOut = () => {
    dispatch({
      type: "LOGOUT",
    });
    navigate("/");
    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;
    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) logOut();
    }
    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);
  return (
    <div className="  sticky top-0 z-[45] overflow-hidden">
      <div className="flex  justify-between gap-4 bg-primary rounded-[5px] items-center w-[100%] shadow-[white]  z-[45] overflow-hidden">
        {/* Logo */}
        <div className="object-contain cursor-pointer ">
          <img src={Logo} alt="Logo" className="lg:w-[20%] w-[20%] " onClick={() => window.scrollTo(0, 0)} />
        </div>

        {/* NavLinks */}
        <div>
          <div className={`lg:hidden flex text-accent cursor-pointer `} onClick={handleMenu}>
            <IconButton color="inherit">
              <MenuIcon fontSize="large" />
            </IconButton>
          </div>
          <div className="items-center justify-between hidden gap-3 mx-3 text-white lg:flex ">
            {user?.result?.email === "olotudav22@gmail.com" && (
              <Link to={"/admin"} className="hover:text-accent " onClick={hideMenu}>
                Admin
              </Link>
            )}

            <Link to={"/"} className="hover:text-accent " onClick={hideMenu}>
              Home
            </Link>
            {["search", "listings"].map((link) => (
              <div key={link} className="flex items-center justify-between capitalize ">
                <Link key={link} to={link} className="flex items-center justify-between transition-colors hover:text-accent">
                  {link}
                </Link>
              </div>
            ))}

            {!user?.result ? (
              <div className="flex items-center justify-between gap-2 ">
                <div>
                  <Link className="transition-colors hover:text-accent" to={"/auth"}>
                    Login
                  </Link>
                </div>
                <div>
                  <span> /</span>
                </div>

                <div>
                  <Link className="transition-colors hover:text-accent" to={"/auth"}>
                    Register
                  </Link>
                </div>

                <div></div>
              </div>
            ) : (
              <div
                onClick={() => navigate(`/gigs/profile/${user?.result?._id}`)}
                className="flex items-center gap-1 font-medium cursor-pointer text-accent ">
                <IconButton>
                  <Avatar src={user?.result?.passport?.url} />
                </IconButton>
                <p className="flex items-center gap-2">
                  <span> {user?.result?.firstName}</span>
                  <span>{user?.result?.lastName}</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className=" lg:hidden text-[25px] w-[100%]   flex z-40  flex-col bg-body p-2  h-[100%]  relative overflow-hidden ">
          <div className=" z-[50] fixed top-0   bg-body  p-3 h-[100vh]  overflow-hidden w-full   ">
            <div className="mt-[20px] ">
              {user?.result?.email === "olotudav22@gmail.com" && (
                <Link to={"/admin"} className="hover:text-accent " onClick={hideMenu}>
                  Admin
                </Link>
              )}
              <Link to={"/"} className="flex mb-4 hover:text-accent" onClick={hideMenu}>
                Home
              </Link>
              {["search", "listings", "category", "filter"].map((link) => (
                <div key={link} onClick={hideMenu} className="flex flex-col capitalize ">
                  <Link key={link} to={link} className="flex justify-between my-4 capitalize transition-colors hover:text-accent">
                    {link}
                  </Link>
                </div>
              ))}
            </div>

            <div className="flex flex-col justify-between gap-2 ">
              <>
                {!user?.result ? (
                  <>
                    <div onClick={hideMenu} className="mb-4 ">
                      <Link className="transition-colors hover:text-accent" to={"/auth"}>
                        Login
                      </Link>
                    </div>
                    <div>
                      <Link onClick={hideMenu} className="transition-colors hover:text-accent" to={"/auth"}>
                        Register
                      </Link>
                    </div>
                  </>
                ) : (
                  <Link
                    onClick={hideMenu}
                    to={`/gigs/profile/${user?.result?._id}`}
                    className="flex justify-start gap-1 font-medium cursor-pointer text-accent ">
                    <IconButton>
                      <Avatar src={user?.result?.passport?.url} />
                    </IconButton>
                    <p className="flex items-center gap-2">
                      <span> {user?.result?.firstName}</span>
                      <span>{user?.result?.lastName}</span>
                    </p>
                  </Link>
                )}
              </>
              <div onClick={hideMenu} className="fixed top-0 right-0 text-accent ">
                <IconButton color="inherit">
                  <CloseIcon fontSize="large" />
                </IconButton>
              </div>

              <div></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
