export default (gigs = [], action) => {
  switch (action.type) {
    case "FETCH_ALL":
      return action.payload;
    case "LIKE":
      return gigs.map((gig) =>
        gig._id === action.payload._id ? action.payload : gig
      );
    case "CREATE":
      return [...gigs, action.payload];
    case "UPDATE":
      return gigs?.map((gig) =>
        gig?.creator === action?.payload?.creator ? action?.payload : gig
      );
    case "FETCH_BY_SEARCH":
      return [...gigs, action.payload.data];
    case "FETCH_CATEGORY":
      return [...gigs, action.payload];
    case "FETCH_DETAILS":
      return [...gigs, action.payload];
    case "FETCH_USERDATA":
      return [...gigs, action.payload];
    case "COMMENT":
      return {
        ...gigs,
        gigs: gigs.map((gig) => {
          if (gig._id === action.payload._id) return action.payload;
          return gig;
        }),
      };
    case "DELETE":
      return gigs.filter((post) => post._id !== action.payload);
    default:
      return gigs;
  }
};
