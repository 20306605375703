import { Pagination , Navigation} from 'swiper/modules'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import Plumbing from "../../images/plumbing1.jpeg"
import Mechanic from "../../images/Mechanic.jpg"
import Cleaning from "../../images/Cleaning.jpg"
import MakeUp from "../../images/MakeUp.jpg"
import Furniture  from "../../images/Furniture.jpg"
const MainSlider = () => {
    
    const sliderData = [
            {id: 1,
                img: Plumbing, 
                text1: "Is Your Pipe Leaking", 
                text2: "Try  Masta Today!!!"
            }, 
            {
                id: 2,
                img: Mechanic, 
                text1: "Did Your Car Just Break Down", 
                text2: "Try  Masta Today!!!"
            }, 
            {id: 3,
                img: Cleaning, 
                text1: "Is Your House Messy", 
                text2: "Try  Masta Today!!!"
            }, 
            {id: 4,
                img:MakeUp, 
                text1: "Did you want to Look Beautiful for your Upcoming Event", 
                text2: "Try  Masta Today!!!"
            }, 
            {id: 5,
                img: Furniture , 
                text1: "Is Your Pipe Leaking", 
                text2: "Try  Masta Today!!!"
            }, 
    ]
  return (
    <div className='flex-[0.6]  bg-primary  xl:bg-mainSlider xl:bg-no-repeat  lg:max-w-full  rounded-[8px] overflow-hidden drop-shadow-2xl object-fill h-[400px] max-h-[400px] '>
    <Swiper className='h-full object-fill' modules={[Pagination,Navigation]} loop={true} navigation={true} 
     pagination={{
        clickable: true
    }}  style={{
        "--swiper-pagination-color": "#F6CD46",
        "--swiper-pagination-bullet-inactive-color": "#999999",
        "--swiper-pagination-bullet-inactive-opacity": "1",
        "--swiper-pagination-bullet-size": "16px",
        "--swiper-pagination-bullet-horizontal-gap": "6px",
        "--swiper-navigation-color": "#F6CD46",
      }}>
        {
            sliderData.map((data)=> (
                <SwiperSlide key={data.id}>
                    <div className='h-full w-[100%] object-cover   relative '>
                    <img className='w-[100%]  h-full object-cover ' src={data.img} alt="" />
                    <div className='flex flex-col  fixed text-black  text-[10px] lg:text-[25px] rounded-[5px] left-1 top-0  '>
                    <h2 className='mb-1 bg-accent  p-2  rounded-r-md '>
                        {
                            data.text1
                        }
                    </h2>
                    <p className='bg-accent  p-2  rounded-b-[18px] '>{data.text2}</p>
                    </div>

                    </div>
                </SwiperSlide>
            ))
        }
    </Swiper>
    </div>
  )
}
export default MainSlider