import React , {useEffect, useState}from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { Avatar, CircularProgress, Paper } from '@mui/material'
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';
import Comment from '../Comment'
import Products from '../../containers/Products'
import { toast } from 'react-toastify'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { likeGig } from '../../actions/index';
const PostDetails = ({setCurrentId}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [data, setData] = useState(null);
  const [isHovered, setIsHovered] = useState(true)
    const {id} = useParams()
    const user = JSON.parse(localStorage.getItem('profile'));
    const [likes, setLikes] = useState(data?.likes);
  const userId = user?.result?._id;
  const name = data?.name1 + " " + data?.name2
  const hasLikedPost = data?.likes?.find((like) => like === userId);
  const handleLike = async () => {
    dispatch(likeGig(data._id));
    
    if (hasLikedPost) {
      setLikes(data?.likes.filter((id) => id !== userId));
    } else {
      setLikes([...data?.likes, userId]);
    }
    };
    const fetchData = async () => {
      try {
        const response = await fetch(`https://masta-api-ae9y.onrender.com/gigs/${id}`); // Replace 'your_api_endpoint' with the actual API endpoint
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error("Error fetching data:", error.message)
      }
    };
    useEffect(()=> {
      window.scrollTo(0,0);
      fetchData(data)
      setLikes(likes)
      }, [])
    useEffect(()=> {
      
      window.scrollTo(0,0);
      }, [setCurrentId])
      
    
  if (!data) return null;
  const Likes = () => {
    if (data?.likes?.length > 0) {
      return likes?.find((like) => like === userId)
        ? (
          <div className='text-[12px] lg:text-[20px]  text-accent'><FavoriteIcon  color='inherit' />&nbsp;{data?.likes?.length > 2 ? `You and ${data?.likes?.length - 1} others` : `${data?.likes?.length} like${data?.likes?.length > 1 ? 's' : ''}` }
          </div>
        ) : (
          <div  className='text-[12px] lg:text-[20px] text-accent'><FavoriteBorderIcon color='inherit' />&nbsp;{data?.likes?.length} {data?.likes?.length === 1 ? 'like' : 'likes'}</div>
        );
    }

    return <div className='text-[12px] lg:text-[20px] text-accent'
    ><FavoriteBorderIcon  color='inherit'/>&nbsp;like</div>;
  };

  return (
    <>
    {
 !data ?  <div className='h-[50vh] flex justify-center items-center text-accent'>
 <CircularProgress  color='inherit' size={"100px"} />
       </div>
    : <>
   <div key={data?._id} className="mb-16  lg:pt-0  mt-3 relative ">
   <div className="container mx-auto relative">
     <div className="flex flex-col lg:flex-row gap-[30px]  ">
       <div className="flex-1  grad rounded-lg flex justify-center items-center object-cover h-[500px]  max-h-[500px] overflow-hidden">
        <div className='flex flex-1  flex-col items-center object-cover  '>
        <img
           className={"h-full  lg:h-full w-full  object-cover mb-2 " }
           src={data?.selectedFile}
           alt=""
         />
         <img
         className={ "h-full  lg:h-full w-full  object-cover mb-2 "   }
         src={data?.selectedFile2}
         alt=""
       />
        </div>
         
       </div>
       <div className="flex-1 max-h-[500px] h-[200px] lg:h-[500px]  relative bg-primary p-12 lg:py-0  lg:px-20 rounded-lg flex flex-col justify-center ">
        <div className=' flex gap-3   items-center'>
        <div onClick={()=> navigate(`/gigs/profile/${data.creator}`)} >
        <div className="flex gap-2 cursor-pointer items-center uppercase text-accent text-lg font-semibold mb-2 text-[20px] lg:text-[35px] ">
          <Avatar src={data?.passport}/>
          <>
          {name}
          </>
          </div>
        </div>
        <div className="flex items-center -mt-2 ">
           <div className="lg:text-3xl text-[18px]  text-accent font-semibold">
             ${data?.prize}
           </div>
          
         </div>
       
        </div>
        
        <div className="flex items-center my-2 gap-2 text-accent">
          <div>
            <LocationOnIcon color="inherit"/>
          </div>
           <div className="lg:text-3xl text-[18px]  text-accent font-semibold">
             {data?.state}
           </div>
          
         </div>
         
         
         <h2 className='text-[18px] lg:text-[25px]  mb-4'> {!data?.skill  === "others..." ? " - " : data?.skill }  {data?.subSkill  ? ` ${data?.subSkill}  ` : null} </h2>
         <p className="mb-2 text-[18px] lg:text-[25px] ">{data?.title} </p>
        
         <div className='flex gap-6  items-center'>
          <div onClick={handleLike} className=' cursor-pointer'>
          <h2 className='text-[18px] lg:text-[25px] ' >
          {/* {data?.likes.length} {data?.likes.length > 1 ? "likes": "like"} */}
          <Likes/>
          </h2>
          </div>
          
          <h2 className='text-[18px] lg:text-[25px] flex gap-1  items-center '>
            <MessageIcon/>
          {data?.comments.length} {data?.comments.length > 1 ? "comments": "comment"}
          </h2>
          
       </div>
       <div className='text-[20px] '>
        Address  : {data?.Address}
       </div>
       <div className='mt-4 '>
        Review : 
       </div>
         {/* User Info */}
       <div className='flex gap-3 items-center mt-4   flex-wrap'>
      <Link to={`tel:${data?.telephone}`} className='flex gap-2 font-semibold p-3 text-[12px] lg:text-[25px] bg-accent-hover text-black hover:bg-accent items-center rounded-[5px] '>
        <CallIcon />
        <p>Telephone</p>
      </Link> 
      {/* <Link to={`mailto:${data?.email}`} className='flex gap-2 font-semibold p-3 text-[12px] lg:text-[25px] bg-accent-hover text-black hover:bg-accent items-center rounded-[5px] '>
        <EmailIcon />
        <p>Mail</p>
      </Link>  */}
      <Link  to={`/chats/${data?.creator}`} className='flex gap-2 font-semibold p-3 text-[12px] lg:text-[25px] bg-accent-hover text-black hover:bg-accent items-center rounded-[5px] '>
        <MessageIcon />
        <p>Message</p>
      </Link> 
       </div>      
       </div>     
     </div>
        </div>
 </div>
 
      <div className='my-4'>
  <Products setCurrentId={setCurrentId}/>
  </div>
  <div className='flex gap-2 items-center  mt-5'>
          <Comment gigs={data}/>
          </div>

 </>
    }
   
   
    </>
    
   
  )
}

export default PostDetails