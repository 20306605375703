import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import { Link,  useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';
import { useDispatch } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import { CircularProgress, IconButton } from '@mui/material';
import Comment from '../components/Comment';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { likeGig } from '../actions/auth';
const Profile =  () => {
  const [data, setData] = useState(null);
  const {id} = useParams()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("Profile")))
  const users = JSON.parse(localStorage.getItem("profile")) 
  const userId = users?.result?._id;
  const [likes, setLikes] = useState(data?.likes);
  const dispatch = useDispatch()
  const navigate = useNavigate()
 
  const hasLikedPost = data?.likes?.find((like) => like === userId);
  const handleLike = async () => {
    dispatch(likeGig(data._id));

    if (hasLikedPost) {
      setLikes(data?.likes.filter((id) => id !== userId));
    } else {
      setLikes([...data?.likes, userId]);
    }
  };
  const Likes = () => {
    if (data?.likes?.length > 0) {
      return likes?.find((like) => like === userId)
        ? (
          <div className='text-[12px] lg:text-[20px] '><FavoriteBorderIcon  />&nbsp;{data?.likes?.length > 2 ? `You and ${data?.likes?.length - 1} others` : `${data?.likes?.length} like${data?.likes?.length > 1 ? 's' : ''}` }
          </div>
        ) : (
          <div  className='text-[12px] lg:text-[20px] '> <FavoriteIcon  />&nbsp;{data?.likes?.length} {data?.likes?.length === 1 ? 'like' : 'likes'}</div>
        );
    }

    return <div className='text-[12px] lg:text-[20px] '
    ><FavoriteBorderIcon  />&nbsp;like</div>;
  };
  useEffect(()=> {
  },[likes, setLikes, hasLikedPost])
  const logOut = ()=> {
    dispatch({
      type: "LOGOUT"
    })
   
    navigate("/")
    window.location.reload()
    window.scrollTo(0,0)
    
    setUser(null)     
} 
const userData = users?.result?._id === id
    const fetchData = async () => {
      try {
        const response = await fetch(`https://masta-api-ae9y.onrender.com/gigs/profile/${id}`); // Replace 'your_api_endpoint' with the actual API endpoint
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
    
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error("Error fetching data:", error.message)
      }
    };

    useEffect(()=> {
      window.scrollTo(0,0);
      fetchData(data)
      }, [])
  
      
  return (
    <div className='flex flex-col my-3'>
      {
        !data ? <div className='h-[50vh] flex justify-center items-center text-accent'>
<CircularProgress  color='inherit' size={"100px"} />
        </div>   : <>
        <div className='flex flex-col items-center lg:justify-around lg:flex-row'>
        <div className='flex items-center object-cover gap-3'>
        <img src={data?.passport?.url ? data?.passport?.url : "https://toppng.com/uploads/preview/app-icon-set-login-icon-comments-avatar-icon-11553436380yill0nchdm.png"} alt={data?.firstName} className='h-[150px] w-[150px] lg:h-[250px]  lg:w-[250px] object-cover rounded-[50%] ' />
        <div>
        <h2 className=' font-semibold text-accent-hover text-[15px] lg:text-[20px] '> {data?.firstName}  {data?.lastName} </h2>
        <p className='text-[15px] lg:text-[20px] '>{data?.email}</p>
        <p className='text-gray-400 text-[15px] lg:text-[20px] '>Gender: {data?.gender}</p>
        <p className='text-gray-400 text-[15px] lg:text-[20px] '>Address: {data?.homeAddress}</p>
        <p className='text-gray-400 text-[15px] lg:text-[20px] '>Nationality: {data?.nationality}</p>
        <p className='text-gray-200 text-[15px] lg:text-[20px] '>Profession: {data?.skill}</p>
        <p className='text-gray-200 text-[15px] lg:text-[20px] '>Telephone: {data?.telephone}</p>

        <div className='flex items-center gap-3'>
          {
            !users &&  <p className='text-gray-400 text-[15px] lg:text-[20px] '> {data?.likes?.length}  {data?.likes?.length > 1 ? "Likes": "Like"}</p>
          }
          {
        users && <>
        <div onClick={handleLike} className=' flex lg:hidden   cursor-pointer  text-[10px] lg:text-[20px]  text-accent bottom-12  left-0 font-meium items-center '  >
       <IconButton color="inherit">
          <Likes />
       </IconButton>
      </div>
      <div onClick={handleLike} className=' hidden lg:flex  text-[10px]   cursor-pointer lg:text-[20px]   text-accent bottom-12  left-0 font-meium items-center '  >
       <IconButton color='inherit'>
          <Likes/>
       </IconButton>
      </div>
        </>
      }
        <p className='text-gray-400 text-[15px] lg:text-[20px] '> {data?.comments?.length}  {data?.comment?.length > 1 ? "Comments": "Comment"}</p>
        </div>
        {
          userData && <p className='text-gray-100 text-[15px] lg:text-[20px] '>Date of Birth: {(data?.dob)}</p>
        }
          
        </div>

        </div>
        <div className='flex flex-wrap items-center gap-2 mt-5 lg:mt-0'>
        {/* {
             (userData) && (
                  <div onClick={()=> navigate(`/user/update/${id}`)} className='flex  gap-2  items-center justify-end lg:mt-0 mt-5  cursor-pointer bg-accent text-black p-2 rounded-[5px] '>
                <EditIcon fontSize='large'/>
                <p className=' font-semibold text-[18px] lg:text-[25px] '>Edit Profile</p>
              </div>
              )
            } */}

            {
              (!userData) &&

              (
              <div className='flex items-center gap-2'>
               <Link to={`tel:${data?.telephone}`} className='flex gap-2 font-semibold p-2 text-[12px] lg:text-[25px] bg-accent-hover text-black hover:bg-accent items-center rounded-[5px] '>
              <CallIcon  />
              <p>Telephone</p>
            </Link>
            <Link to={`mailto:${data?.email}`} className='flex gap-2 font-semibold p-2 text-[12px] lg:text-[25px] bg-accent-hover text-black hover:bg-accent items-center rounded-[5px] '>
        <EmailIcon />
        <p>Mail</p>
      </Link>
      {/* <Link  to={`/chat/${data?._id}`} className='flex gap-2 font-semibold p-2 text-[12px] lg:text-[25px] bg-accent-hover text-black hover:bg-accent items-center rounded-[5px] '>
        <MessageIcon />
        <p>Message</p>
      </Link>  */}
      </div>
      )
             
            }
             
        </div>
            
      </div>
        </>
      }
      {/* user Info */}
      <div>

      </div>
      
      <div className='mt-4 text-gray-500 bg-gray-600'>
        {/* Comments */}
        <hr />
        {data?.comments?.length > 0 &&  <div className='bg-body '>
          <h2 className='font-bold text-gray-200'>Comments</h2>
          <div className='px-3 py-1 max-h-[100px] overflow-scroll category'>
          {data?.comments?.map((comment, index)=> (
            <div key={index} className=''>
                 <p className='text-gray-300'> {comment}</p>
              </div>
         
          ))}
          </div>
          
        </div>}
       

      <hr />
      <div className=' mt-3  cursor-pointer bg-body text-accent  flex justify-between text-[25px] items-center px-[10px] '>
        {/* <Link to={`/updatePassword/${users?.result?._id}`}>forgotten password?</Link> */}
        <div>
        <div onClick={logOut}  className='container flex items-center justify-end'>
        <LogoutIcon/> <span>Logout</span>
        </div>
        </div>
        
      </div>
      </div>
      <Comment gigs={data}/>
    </div>
  )
}

export default Profile