import React from 'react'
import FreeShing from "../../images/FreeProv.png"
import  Order from "../../images/OrderProv.png"
import Stress from "../../images/StressProv.png"
import Promotion from "../../images/PromProv.png"
import buying from "../../images/BuyingProv.png"
import Customer from "../../images/CareProv.png"
const Provider = () => {
    const ProviderData = [
        { id:1,
          img: FreeShing,
          text: "Free Services", 

         },
        { id:2,
          img:Order,
          text: "Online Orders", 

         },
        { id:3,
          img: Stress,
          text: "Save Stress", 

         },
        { id:4,
          img:Promotion,
          text: "Promotion", 

         },
        { id:5,
          img: buying,
          text: "Happy Buying", 

         },
        { id:6,
          img: Customer,
          text: "Customer Care", 
         
         },
]
  return (
    <div>
      <div  className='flex items-center justify-center '>
        <h2 className=" mb-2 text-center px-10 py-[10px]  rounded-[5px] flex justify-center items-center text-black font-semibold text-[20px] lg:text-[25px] bg-accent   ">
        Features 
        </h2>
        </div>
      <div className='flex justify-center mx-auto my-6 '>
        <div className='flex flex-wrap items-center gap-2 justify-evenly lg:gap-10'>
          {ProviderData.map((data, index)=> (
            <div key={data.id} className=' bg-primary p-3  cursor-pointer shadow-gray-600 shadow-2xl  hover:shadow-gray-700 opacity-95 rounded-[5px]
             flex flex-col item-center w-[100px] md:w-[200px]  lg:w-[300px] text-center items-center ' >
                <img src={data.img}  alt={data.text} className=' mb-[30px] object-cover h-full flex items-center ' />
                <h6 className='text-[10px] lg:text-[20px] text-center bg-accent text-black font-medium rounded-[8px] p-2  flex flex-wrap '>{data.text}</h6>
            </div>
          ))}
        </div>
    </div>
    </div>
    
  )
}

export default Provider