import React, { useState } from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import {
  Button,
  Typography,
  Grid,
  Container,
  Paper,
  Avatar,
} from "@mui/material";
import FileBase from "react-file-base64";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Input from "./Input";
import {signin, signup} from "../../actions/auth" 
import Masta from "../../images/MASTA-logo.png";
import Icon from "./Icon";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
const Auth = () => {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    confirmPassword: "",
    password: "",
    telephone: "",
    gender: "",
    skill: "",
    language: "",
    nationality: "",
    passport: "",
    dob: "",
    subSkill: "",
    education: "",
    homeAddress: "",
    homeBusStop: "",
    state: "",
    officeAddress: "",
    officeBusStop: "",
    idName: "",
    nin: "",
    issuedate: "",
    expiryDate: "",
    idTelephone: "",
    maritalStatus: "",
    officestate: "", 
    officetelephone: "",
    kinaddress: "",
    kinname:"",
    kingender: "",
    kinemail: "",
    kintelephone: ""
  };
  const [showPassword, setShowPassword] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [mode, setMode] = useState(true)
  const [selectedSkill, setSelectedSkill] = useState('');
  const [selectedSubSkill, setSelectedSubSkill] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const switchMode = () => {
    setIsSignUp((prev) => !prev);
    window.scrollTo(0,0)
    handleShowPassword(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault()
      try {
        if(isSignUp){
          dispatch(signup(formData, navigate))
        }
        else{
          dispatch(signin(formData, navigate))
        }
      } catch (error) {
        toast.error("Invalid Credentails")
        
      }
  };
  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  };
  const googleSuccess = async (res) => {
    const result = res?.profileObj;
    const token = res?.tokenId;

    try {
      dispatch({ type: "AUTH", data: { result, token } });

      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const googleError = () =>
    alert("Google Sign In was unsuccessful. Try again later");
  const buyer = () => {
    setMode(true)
  };
  const seller = () => {
    setMode(true)
  };
  
  return (
    <Container className="">
      <Paper
        className={
          "mt-[64px] bg-primary mb-[10px] flex flex-col items-center p-[8px]   justify-center "
        }>
        
        <div className="flex items-center justify-center ">
          <img
            src={Masta}
            alt="Logo"
            className="w-[30%] lg:w-[20%]  object-cover"
          />
        </div>

        <Typography variant="h5">
          {" "}
          {isSignUp ? "Sign Up" : "Sign In"}
        </Typography>
        <Typography variant="h5" className="text-gray-400">
          {" "}
         Please Fill Carefully 
        </Typography>
        {/* {
          isSignUp &&  <div className="flex justify-center items-center mt-[10px] ">
          <div className="flex items-center gap-4">
            <Button  onClick={seller}
              fullWidth
              variant="contained"
              color="primary">Buyer</Button>
            <Button  onClick={buyer}
              fullWidth
              variant="contained"
              color="primary">Seller</Button>
          </div>
          
         </div>
        } */}
       
        <form className={"w-[100%] mt-[24px] "} onSubmit={handleSubmit}>
          <h2
            className="text-[25px] font-medium  uppercase mb-3"
            color="primary">
            {isSignUp ? " Personal Details" : ""}
          </h2>
          <Grid container spacing={2}>
            {isSignUp && (
              <>
                <Input
                  name="firstName"
                  label="First Name"
                  handleChange={handleChange}
                  autoFocus
                  half
                />
                <Input
                  name="lastName"
                  label="Last Name"
                  handleChange={handleChange}
                  half
                />
                <div className="flex flex-col justify-around w-full lg:flex-row">
                <div className=" mx-4 lg:mx-6 mt-[10px]  flex items-center gap-3 ">
                  <div className="text-[16px] lg:text-[20px] font-medium text-gray-500">
                    Gender:{" "}
                  </div>
                   <select defaultValue={"DEFAULT"} className='block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded-md leading-tight focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 lg:w-[200px] '  onChange={(e) => setFormData({...formData, gender: e.target.value })} >
                    <option disabled value="DEFAULT">
                      Gender
                    </option>
                    {
                          [ "Male", "Female" , "Rather Not Say"].map((gender, id)=>  (
                            <option value={gender}  key={id}  className='bg-[whitesmoke]  text-gray-600 overflow-hidden h-fit hover:bg-accent  outline-none'>
                                {gender}
                            </option>
                          )
                          ) 
                    }
                    </select>    
                </div>
                  
                <div className=" mx-4 lg:mx-6 mt-[10px]  flex items-center gap-3 ">
                  <div className="text-[16px] lg:text-[20px] font-medium text-gray-500">
                    Nationality:{" "}
                  </div>
                   <select defaultValue={"DEFAULT"} className='block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded-md leading-tight focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 lg:w-[200px]'  onChange={(e) => setFormData({...formData, nationality: e.target.value })} >
                   <option disabled value="DEFAULT" >
                      Nationality
                    </option>
                    {
                          ["Nigerian", "Others"].map((gender, id)=>  (
                            <option value={gender}  key={id}  className='bg-[whitesmoke]  text-gray-600  overflow-hidden h-fit hover:bg-accent  outline-none'>
                                {gender}
                            </option>
                          )
                          ) 
                    }
                    </select>    
                </div>
                <>
                {mode &&  <div className=" mx-4 lg:mx-6 mt-[10px]  flex items-center gap-3 ">
                  <div className="text-[16px] lg:text-[20px] font-medium text-gray-500 flex  gap-1 items-center">
                    <span> Marital</span> <span>Status:</span>  {" "}
                  </div>
                   <select defaultValue={"DEFAULT"} className='block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded-md leading-tight focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 lg:w-[200px]'  onChange={(e) => setFormData({...formData, maritalStatus: e.target.value })} >
                   <option disabled value="DEFAULT">
                      Marital Status
                    </option>
                    {
                          ["Single","Married", "Divorce"].map((gender, id)=>  (
                            <option value={gender}  key={id}  className='bg-[whitesmoke]  text-gray-600  overflow-hidden h-fit hover:bg-accent  outline-none'>
                                {gender}
                            </option>
                          )
                          ) 
                    }
                    </select>    
                </div>}
                </>
                </div>
                 
                {/* Skills & Subskilss */}
                <> {
                  mode && 
                  <div className="flex flex-wrap items-center gap-2 ">
                  {/* Skills */}
                    <div  className="mx-4 lg:mx-6 mt-[10px]  flex items-center gap-3 text-black justify-center">
                  <div className="text-[16px] lg:text-[20px] font-medium text-gray-500 flex  gap-1 items-center">
              <span> Select</span> <span>Profession:</span>
            </div>

  <select defaultValue={"DEFAULT"} className="block appearance-none w-[100%] lg:w-[250px] bg-[whitesmoke]   font-medium border border-gray-300 hover:border-gray-500  py-4  px-6 rounded-md leading-tight focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200  category  "onChange={(e) => setFormData({...formData, skill: e.target.value })}  >
  <option disabled value="DEFAULT" className="text-acccent"   >Select a Profession</option>
              {
                ["A.C & Refrigeration Services", "Aluminium & Glass", "Appliances & Electronics","AUTO - Mechanic & Rewire & Panel", "Barber", "Beautician", "Brick Layer & Tiller & POP", "Carpentary Services", "Catering Services", "Cleaning & Laundary & Fumigation", "Dispatch Rider", " DSTV & CCTV", "Electrical & Inverter Services", "HairStylist", "Haulage & Movers", "Home Interior Fitting", "Make-Up Artist", "Painter & Screeder & Wallpaper" , " Phone & Laptop", "Photographer & Video", "Plumbing Services", "Real Estate Realtor", "Testing-Official Use", "Vehicle Towing", "Welder Services", "Fashion Designer"].map((skills)=> (
                  <option key={skills} value={skills}  className=" py-6 border-b-[1px] border-gray-300  font-medium ">{skills}</option>
                ))
              }
</select>
            </div>

            {/* SubSkill */}
            {/* <div className="mx-4 lg:mx-6 mt-[10px]  flex items-center gap-3 text-black justify-center">
            <div className="text-[16px] lg:text-[20px] font-medium text-gray-500 flex  gap-1 items-center">
              <span> Sub</span> <span> Profession:</span>  {" "}
            </div>
            <select defaultValue={"DEFAULTs"} className="block appearance-none w-[100%] bg-[whitesmoke]   font-medium border border-gray-300 hover:border-gray-500  py-4  px-6 rounded-md leading-tight focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 lg:w-[200px] category  "onChange={(e) => setFormData({...formData, subSkill: e.target.value })}  >
                <option  disabled value="DEFAULTs"  className="text-black" >Sub Profession</option>
                {
                  formData.skill === "A.C/ Refrigeration Services" && (
                    ["A.C Gas Filling","A.C Repair and Installation", "Refrigerator Repair", "Freezer Reapair", "Water " ].map((sub)=> (
                      <option key={sub} value={sub} >{sub}</option>
                    ))
                  )
                }
                {
                  formData.skill === "Aluminium / Glass" && (
                    ["Aluminium Sliding Doors", "Aluminium" ].map((sub)=> (
                      <option key={sub} value={sub} >{sub}</option>
                    ))
                  )
                }
                {
                  formData.skill === "A.C/ Refrigeration Services" && (
                    ["A.C Gas Filling","A.C Repair and Installation", "Refrigerator Repair", "Freezer Reapair", "Water " ].map((sub)=> (
                      <option key={sub} value={sub} >{sub}</option>
                    ))
                  )
                }
                {
                  formData.skill === "A.C/ Refrigeration Services" && (
                    ["A.C Gas Filling","A.C Repair and Installation", "Refrigerator Repair", "Freezer Reapair", "Water " ].map((sub)=> (
                      <option key={sub} value={sub} >{sub}</option>
                    ))
                  )
                }
                {
                  formData.skill === "A.C/ Refrigeration Services" && (
                    ["A.C Gas Filling","A.C Repair and Installation", "Refrigerator Repair", "Freezer Reapair", "Water " ].map((sub)=> (
                      <option key={sub} value={sub} >{sub}</option>
                    ))
                  )
                }

          </select>
            </div> */}
            </div>
                  
                }
                
                </>
                {/* Subskills End */}
               
                <Input
                  name={"dob"}
                  label={"Date of Birth"}
                  handleChange={handleChange}
                  placeholder={"DD/MM/YYYY"}
                  half
                  
                />
                <div className=" ml-4 lg:ml-6 mt-[10px]  flex items-center gap-3 ">
                  <div className="text-[16px] lg:text-[20px] font-medium text-gray-500">
                    Passport:{" "}
                  </div>
                  <FileBase type="file" multiple={false} onDone={({ base64 }) => setFormData({ ...formData, passport: base64 })} />
                </div>
              </>
            )}

            <Input
              name={"email"}
              label={"Email Address"}
              handleChange={handleChange}
              type={"email"}
              half
            />
            {isSignUp && (
              <>
                <Input
                  name={"telephone"}
                  label={"Telephone Number"}
                  handleChange={handleChange}
                  type={"tel"}
                half
                />
                <Input
                  name={"language"}
                  label={"Languages"}
                  handleChange={handleChange}
                 half
                />
                <>
                {mode && 
               
              <Input
              name={"education"}
              label={"Education"}
              handleChange={handleChange}
             placeholder={"JSSCE, SSCE,OND, NCE,BSC"}
             
            />
                }
                
                </>
               
              </>
            )}

            <Input
              name={"password"}
              label={"Password"}
              handleChange={handleChange}
              type={showPassword ? "text" : "password"}
              handleShowPassword={handleShowPassword}
              half
            />

            {isSignUp && (
              <>
                <Input
                  name={"confirmPassword"}
                  label={"Confirm Password"}
                  handleChange={handleChange}
                  type={"password"}
                  half
                />
              </>
            )}
            { <>
            
            {/* Address Information */}
            {isSignUp && (
              <>
                <h2
                  className="text-[25px] ml-6 mt-2   justify-center flex items-center   uppercase font-medium "
                  color="primary">
                  Address Information
                </h2>
                {!mode && <>
                  <Input
                  name={"homeAddress"}
                  label={"Home Address"}
                  handleChange={handleChange}
                />
                <Input
                  name={"homeBusStop"}
                  label={"Local Government"}
                  handleChange={handleChange}
                  half
                />
                <Input
                  name={"state"}
                  label={"State"}
                  handleChange={handleChange}
                  half
                />
                </>}
               
                {
                  mode && 
                  <>
                  <Input
                  name={"officeAddress"}
                  label={"Office Address"}
                  handleChange={handleChange}
                />
                <Input
                  name={"officeBusStop"}
                  label={"Local Government Area"}
                  handleChange={handleChange}
                  half
                />
                <Input
                  name={"officestate"}
                  label={"State"}
                  handleChange={handleChange}
                  half
                /></>
                }
                
              </>
            )}
            </>}
           {mode && <>
           
            {/* Nin Details */}
            {isSignUp && (
              <>
                <h2
                  className="text-[25px] ml-6 mt-2   justify-center flex items-center   uppercase font-medium "
                  color="primary">
                  nin Details
                </h2>
                <Input
                  name={"idName"}
                  label={"ID Name"}
                  handleChange={handleChange}
                />
                <Input
                  name={"nin"}
                  label={"ID Number"}
                  handleChange={handleChange}
                  half
                />
               
                <Input
                  name={"idTelephone"}
                  label={"Id Telephone Number"}
                  handleChange={handleChange}
                  half
                />
              </>
            )}
           </>}
            
           {
            mode && <>
             {/* Next of Kin */}
             {isSignUp && (
              <>
                <h2
                  className="text-[25px] ml-6 mt-2   justify-center flex items-center   uppercase font-medium "
                  color="primary">
                  next of kin
                </h2>
                <Input
                  name={"kinaddress"}
                  label={"Address"}
                  handleChange={handleChange}
                />
                <Input
                  name={"kinname"}
                  label={"Full Name"}
                  handleChange={handleChange}
                  half
                />
               <div className=" mx-4 lg:mx-6 mt-[10px]  flex items-center gap-3 ">
                  <div className="text-[16px] lg:text-[20px] font-medium text-gray-500">
                    Gender:{" "}
                  </div>
                   <select defaultValue={"DEFAULT"} className='block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded-md leading-tight focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 lg:w-[200px] '  onChange={(e) => setFormData({...formData, kingender: e.target.value })} >
                    <option disabled value="DEFAULT">
                      Gender
                    </option>
                    {
                          [ "Male", "Female" , "Rather Not Say"].map((gender, id)=>  (
                            <option value={gender}  key={id}  className='bg-[whitesmoke]  text-gray-600 overflow-hidden h-fit hover:bg-accent  outline-none'>
                                {gender}
                            </option>
                          )
                          ) 
                    }
                    </select>    
                </div>
                <Input
                  name={"kinemail"}
                  label={"Email Address"}
                  handleChange={handleChange}
                  type={"email"}
                  half
                />
                <Input
                  name={"kintelephone"}
                  label={"Telephone Number"}
                  handleChange={handleChange}
                  half
                />
              </>
            )}
            </>
           }
           

            {isSignUp && (
              <div className="ml-6  border-none mt-2 text-[12px] lg:text-[18px]   gap-2  capitalize  text-gray-500 ">
                <>
                  <input
                    type="checkbox"
                    name="check"
                    required
                    className="text-[20px] "
                  />
                  &nbsp;
                  <label id="check">
                    By signing this form with MASTA.NG you consent that your
                    details will be uploaded to our website as potential
                    customers would reach out via your contact once your
                    services are needed.
                  </label>
                </>
              </div>
            )}
          </Grid>
          <div className="mt-3 w-[100%] ">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={"mt-5 bg-accent"}>
              {isSignUp ? "Sign Up" : " Sign In"}
            </Button>
          </div>
          <div className="flex justify-center w-full my-2 ">
            {/* <GoogleOAuthProvider clientId="764465202282-28eli39f113lkkdc0b8m1h49d2ig9ika.apps.googleusercontent.com">
              <GoogleLogin onSuccess={googleSuccess} onFailure={googleError} />
            </GoogleOAuthProvider> */}
          </div>
          <Grid
            container
            justifyContent="flex-end "
            className="font-medium text-accent">
            <Grid item>
              <Button onClick={switchMode} color="inherit">
                {isSignUp
                  ? "Already have an account? Sign in"
                  : "Don't have an account? Sign Up"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default Auth;
